import {
    ILocationAreaModel,
} from '@src/api/hooks/useGetLocationArea'
import {
    IGatewayByIdResponse,
    ILocationModel,
} from '@src/api/hooks/useGetGatewayById'
import {
    IConfirmInstallationResponse,
} from '@src/api/hooks/useConfirmInstallation'
import useSessionStorageState from '../useSessionStorageState/useSessionStorageState'

export interface ISetupState {
    gatewayMac?: string
    gatewayInfo?: IGatewayByIdResponse
    selectedLocation?: ILocationModel
    selectedArea?: ILocationAreaModel
    installationResult?: IConfirmInstallationResponse,
}

const useSetup = (): ReturnType<typeof useSessionStorageState<ISetupState>> => {
    return useSessionStorageState<ISetupState>('SETUP', {})
}

export default useSetup
