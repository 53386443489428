import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    `,
    progress: css`
        padding: 24px;
    `,
}

export default classes
