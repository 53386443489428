import {
    css,
} from '@emotion/react'
import {
    MQ,
} from '@src/constants/MediaQuery'
import THEME from '@src/constants/Theme'

const classes = {
    root: css`
        display: flex;
        justify-content: space-between;
        background-color: ${THEME.skycell_dark};
    `,
    logo: css`
        padding: 16px 0 16px 16px;
        display: flex;
        flex: 1;
        width: 20%;

        img {
            width: 25px;
            height: 26px;
        }

        div {
            padding-left: 8px;
            color: white;
            line-height: 26px;
            font-size: 12px;
    `,
    brandTitle: css`
      display: block;
    `,
    title: css`
        flex: 2;
        padding: 16px 8px;
        font-size: 20px;
        line-height: 24px;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        white-space: nowrap;
        width: 60%;
    `,
    menu: css`
        text-align: right;
        ${MQ.sm} {
            width: 90px;
        }
        flex: 1;
        width: 20%;
    `,
    menuSvg: css`
        padding: 16px;
    `,
}

export default classes
