import React, {
    useCallback,
} from 'react'

import {
    useNavigate,
} from 'react-router-dom'
import APP_URLS from '@src/constants/AppUrls'
import consoleService from '@src/services/consoleService'
import openSupport
    from '@src/utils/jiraHelpDesk'
import queryClient
    from '@src/services/queryClient'
import useSetup from '@src/hooks/useSetup/useSetup'
import useAuthContext from '@src/hooks/useAuthContext'
import MainPopup from './components/MainPopup'

type Props = {
    isOpen: boolean
    onClose?: () => void
}

function MainPopupContainer({
    isOpen, onClose,
}: Props): JSX.Element {
    const [
        , setState,
    ] = useSetup()
    const navigate = useNavigate()
    const {
        userInfo, logout,
    } = useAuthContext()
    const handleInstallClick = useCallback(() => {
        setState(() => {
            return {}
        })
        queryClient.resetQueries()

        navigate(APP_URLS.scanQR)
        if (onClose) {
            onClose()
        }
    }, [
        navigate,
        onClose,
        setState,
    ])

    const jiraHelpDeskCallback = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleSupportClick = useCallback(() => {
        jiraHelpDeskCallback()
        if (onClose) {
            onClose()
        }
    }, [
        jiraHelpDeskCallback,
        onClose,
    ])

    const handleLogoutClick = useCallback(() => {
        logout()
        if (onClose) {
            onClose()
        }
    }, [
        logout,
        onClose,
    ])

    return (
        <MainPopup
            userInfo={userInfo}
            isOpen={isOpen}
            onClose={onClose}
            onInstallClick={handleInstallClick}
            onSupportClick={handleSupportClick}
            onLogoutClick={handleLogoutClick}
        />
    )
}

export default MainPopupContainer
