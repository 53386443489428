import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'

import PhotosContext, {
    PhotosContextType,
    PhotoType,
} from './PhotosContext'
import mergePhotos from './utils'

type Props = {
    children: React.ReactNode,
}

const PhotosProvider = ({
    children,
}: Props) => {
    const [
        photos,
        setPhotos,
    ] = useState<PhotoType[]>([])

    const addPhoto = useCallback((base64: string) => {
        setPhotos((prev) => {
            return [
                ...prev,
                ...[{
                    base64,
                } as PhotoType],
            ]
        })
    }, [])
    const removePhoto = useCallback((index: number) => {
        setPhotos((prev) => {
            const copy = [...prev]

            copy.splice(index, 1)
            return copy
        })
    }, [])

    const savePhotoIds = useCallback((newData: PhotoType[]) => {
        setPhotos((prev) => {
            return mergePhotos(newData, prev)
        })
    }, [])

    const cleanUp = useCallback(() => {
        setPhotos([])
    }, [])

    const contextValue = useMemo<PhotosContextType>(() => {
        return {
            photos,
            addPhoto,
            removePhoto,
            savePhotoIds,
            cleanUp,
        }
    }, [
        addPhoto,
        photos,
        removePhoto,
        savePhotoIds,
        cleanUp,
    ])

    return (
        <PhotosContext.Provider value={contextValue}>
            {children}
        </PhotosContext.Provider>
    )
}

export default PhotosProvider
