import {
    css,
} from '@emotion/react'

const classes = {
    popup: css`
        background-color: white;
        width: 100%;
        height: 100hv;
        display: flex;
        flex-direction: column;
    `,
    root: css`
        padding: 24px 32px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    `,
    content: css`
        flex-grow: 1;
    `,
    hr: css`
        border-top: 1px solid black;
        opacity: 0.12;
        margin-top: 8px;
        margin-bottom: 8px;
    `,
    devInfo: css`
        background-color: white;
        right: 0;
        border: 1px solid red;
        font-size: 13px;
        a {
            color: black;
            display: block;
            padding: 4px;
        }
    `,
}

export default classes
