import React, {
    useState,
} from 'react'
import classes from './JsonPreview.style'

type Props = {
    json: unknown
}

function JsonPreview({
    json,
}: Props): JSX.Element | null {
    const [
        isShown,
        setIsShown,
    ] = useState(false)

    return (
        <div css={classes.root}>
            <div
                css={classes.button}
                onClick={() => {
                    return setIsShown((e) => {
                        return !e
                    })
                }}
            >
                {isShown ? 'hide' : 'show'}
            </div>
            {isShown && <pre css={classes.preview}>{JSON.stringify(json, null, 2)}</pre>}
        </div>
    )
}

export default JsonPreview
