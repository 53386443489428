import {
    createContext, useEffect, useState,
} from 'react'
import useDeepCompareMemorized
    from '../useDeepCompareMemorized/useDeepCompareMemorized'

export interface IFormContext {
    values: Record<string, unknown>
    touched: Record<string, unknown>
    setFieldValue: (name: string, value: unknown) => void
    setFieldTouched: (name: string, value: boolean) => void
}

export interface IFormProps<TValues> {
    initialValues: TValues
    enableReinitialize?: boolean
    onSubmit: (values: TValues) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IUseFormReturn<TValues = any> {
    values: TValues
    touched: Record<string, boolean>
    setFieldValue: (name: string, value: unknown) => void
    setFieldTouched: (name: string, value: boolean) => void
    handleSubmit: (newValues: TValues) => void
}
export const FormContext = createContext<IFormContext>({
    values: {},
    touched: {},
    setFieldValue: () => {
        return null
    },
    setFieldTouched: () => {
        return null
    },
})

function useForm<TValues>(props: IFormProps<TValues>): IUseFormReturn<TValues> {
    const [
        values,
        setValues,
    ] = useState<TValues>(props.initialValues)
    const [
        touched,
        setTouched,
    ] = useState<Record<string, boolean>>({})

    useEffect(() => {
        if (props.enableReinitialize) {
            setValues(props.initialValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.enableReinitialize,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useDeepCompareMemorized(props.initialValues),
    ])
    return {
        values,
        touched,
        setFieldValue: (name: string, newValue: unknown) => {
            setValues((prev) => {
                return {
                    ...prev,
                    [name]: newValue,
                }
            })
        },
        setFieldTouched: (name: string, newValue: boolean) => {
            setTouched((prev) => {
                return {
                    ...prev,
                    [name]: newValue,
                }
            })
        },
        handleSubmit: (newValues: TValues) => {
            // check validation here
            props.onSubmit(newValues)
        },
    }
}

export default useForm
