import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    useParams,
    useNavigate,
} from 'react-router-dom'

import Loading from '@src/shared-components/Loading'
import useSetup from '@src/hooks/useSetup/useSetup'
import useGetGatewayById from '@src/api/hooks/useGetGatewayById'
import ErrorWithSupportPage from '@src/pages/ScanQRError/ErrorWithSupportPage'
import useT from '@src/hooks/useT/useT'
import {
    GatewayStatus,
} from '@src/types/enums'
import useGatewayScanned from '@src/hooks/useGatewayScanned'
import APP_URLS from '@src/constants/AppUrls'

const GatewayReceivedByUrl = () => {
    const {
        gateway = '',
    } = useParams()
    const navigate = useNavigate()
    const [
        _state,
        setState,
    ] = useSetup()
    const {
        t,
    } = useT()

    const {
        data: gatewayByIdData,
        isLoading: gatewayByLoading,
        error: gatewayByIdError,
    } = useGetGatewayById(gateway, {
        enabled: !!gateway,
        retry: false,
    })

    const validGateway = useMemo(() => {
        return gatewayByIdData && gatewayByIdData.gatewayStatus !== GatewayStatus.INSTALLED
    }, [gatewayByIdData])

    useGatewayScanned({
        gatewayByIdData: validGateway ? gatewayByIdData : undefined,
        gateway,
        gatewayByIdError,
        setState,
    })

    const onErrorBack = useCallback(() => {
        navigate(APP_URLS.scanQR)
    }, [navigate])

    if (gatewayByIdError || (gatewayByIdData && !validGateway)) {
        return (
            <ErrorWithSupportPage
                message={t('GATEWAY_NOT_FOUNT_ERROR')}
                header={t('GATEWAY_NOT_FOUNT_HEADER')}
                onBackClick={onErrorBack}
            />
        )
    }

    return (
        <Loading isLoading={gatewayByLoading} />
    )
}

export default GatewayReceivedByUrl
