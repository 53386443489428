import {
    ExtendedProfile, UserInfo,
} from './Auth.types'

const getUserInfo = (extendedProfile: ExtendedProfile, avatar = ''): UserInfo => {
    const {
        assignedRoles = [],
        attributes,
        email,
        name,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = extendedProfile as any

    return {
        avatar,
        assignedRoles,
        email,
        name,
        attributes,
    } as UserInfo
}

export default getUserInfo
