import {
    UseMutationResult, useMutation,
} from '@tanstack/react-query'
import axios from 'axios'
import API_URLS from '@src/constants/ApiUrls'
import {
    IGatewayByIdResponse,
} from '@src/api/hooks/useGetGatewayById'

import useGetAxiosConfig from './useGetAxiosConfig'

const useCheckGatewayId = (
    onSuccess: (data: IGatewayByIdResponse)=>void,
    onError: (error: unknown) => void,
): UseMutationResult<
    IGatewayByIdResponse,
    unknown,
    { id: string }
> => {
    const config = useGetAxiosConfig()

    return useMutation(
        {
            retry: false,
            throwOnError: false,
            mutationFn: ({
                id,
            }) => {
                return axios.get(API_URLS.getGatewayByIdUrl(id), config).then((data) => {
                    return data.data
                })
            },
            mutationKey: ['useCheckGatewayId'],
            onSuccess,
            onError,

        },
    )
}

export default useCheckGatewayId
