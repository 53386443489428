import {
    useContext,
} from 'react'

import PhotosContext from './PhotosContext'

const usePhotosContext = () => {
    return useContext(PhotosContext)
}

export default usePhotosContext
