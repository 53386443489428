import React, {
    ReactNode,
} from 'react'
import CloseSvg from '@src/assets/close.svg'
import classes from './Popup.style'

type Props = {
    isOpen?: boolean
    children: ReactNode
    className?: string
    onClose?: () => void
}

function Popup({
    isOpen, children, className, onClose,
}: Props): JSX.Element {
    return (
        <>
            <div
                onClick={onClose}
                css={classes.blockPanel(isOpen)}
            />
            <div
                css={classes.popup(isOpen)}
                className={className}
            >
                <div css={classes.header}>
                    <div
                        css={classes.closeIconCnt}
                        onClick={onClose}
                    >
                        <img
                            alt="Close"
                            src={CloseSvg}
                            css={classes.closeIcon}
                        />
                    </div>
                </div>
                {children}
            </div>
        </>
    )
}

export default Popup
