import React from 'react'
import {
    Global, css,
} from '@emotion/react'

function GlobalStyles() {
    return <Global styles={styles} />
}

const styles = css`
  html,
  body {
    height: 100%;
    min-height: 100%;
    font-family: Roboto, Muli, Helvetica Neue, Arial, sans-serif;
    margin: 0;
    padding: 0;
    -ms-user-select: none;
    user-select: none;
  }
  #root {
    height: 100%;
    min-height: 100%;
  }
`

export default GlobalStyles
