import getCameraOrientation from '@src/utils/getCameraOrientation'
import VIDEO_CONSTRAINS from '@src/utils/videoConstrains'
import {
    useState, useEffect, useCallback,
} from 'react'

const getAspectRatioConstrains = () => {
    return VIDEO_CONSTRAINS[getCameraOrientation()]
}

const useCameraResolutionConstrains = () => {
    const [
        videoResolutionConstrains,
        setVideoResolutionConstrains,
    ] = useState(getAspectRatioConstrains())

    const onOrientationChange = useCallback(() => {
        setVideoResolutionConstrains(getAspectRatioConstrains())
    }, [])

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        screen.orientation.addEventListener('change', onOrientationChange)
        return () => {
            // eslint-disable-next-line no-restricted-globals
            screen.orientation.removeEventListener('change', onOrientationChange)
        }
    }, [onOrientationChange])

    return {
        videoResolutionConstrains,
    }
}

export default useCameraResolutionConstrains
