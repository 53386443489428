import {
    css,
} from '@emotion/react'

export const BARCODE_MARGIN = 12
const classes = {
    root: css`
        display: flex;
        justify-content: center;
        margin: 0px 24px;
    `,
    rectangleImg: css`
        width: 341px;
        height: 335px;
    `,
    video: css`
        margin: ${BARCODE_MARGIN}px;
    `,
}

export default classes
