import {
    layoutHeaderHeigth,
} from './constants'

export const checkLocationServicePermissions = async (grantedCallback: () => void) => {
    const permissionStatus = await navigator?.permissions?.query({
        name: 'geolocation',
    })

    if (permissionStatus?.state === 'granted') {
        grantedCallback()
    }
}

export const calculateMapContainerStyles = (footerHeight: number) => {
    const mapOffset = layoutHeaderHeigth + footerHeight

    return {
        width: '100%',
        height: `calc(100vh - ${mapOffset}px)`,
    }
}

export const convertLatLngToLiteral = (location: google.maps.LatLng): google.maps.LatLngLiteral => {
    return {
        lat: location.lat(),
        lng: location.lng(),
    }
}

export const convertCoordsToLiteral = (coor: GeolocationCoordinates): google.maps.LatLngLiteral => {
    return {
        lat: coor.latitude,
        lng: coor.longitude,
    }
}
