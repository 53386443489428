import {
    css,
} from '@emotion/react'
import THEME from '@src/constants/Theme'

const classes = {
    rootLayout: css`
        background-color: ${THEME.skycell_primary_color};
    `,
    root: css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height:100%;
        justify-content: center;
    `,
    logoContainer: css`
       display: flex;
    `,
    logo: css`
        width: 242px;
        height: 242px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 48px;
        padding-bottom: 48px;
    `,

    form: css`
        overflow: hidden;
        display: grid;
        grid-template-columns: auto 80%;
        padding: 0px 56px;
        grid-row-gap: 16px;
    `,

    label: css`
        color: white;
        line-height: 32px;
        padding-right: 24px;
    `,
    labelValue: css`
        color: black;
        background-color: white;
        border-radius: 8px 8px 8px 8px;
        line-height: 32px;
        padding-left: 8px;
        padding-right: 8px;
    `,
}

export default classes
