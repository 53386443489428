import React from 'react'

import useT from '@src/hooks/useT'
import CoordinatesIcon from '@src/assets/coordinates-small.svg'
import Typography from '@src/shared-components/Typography'
import AddressIcon from '@src/assets/address-pin_outlined.svg'

import classes from './Coordinates.style'

function Coordinates({
    address,
    coordinates,
}:{
    address: string,
    coordinates: google.maps.LatLngLiteral,
}): JSX.Element {
    const {
        t,
    } = useT()

    return (
        <div css={classes.coordinatesCnt}>
            <div css={classes.row}>
                <div
                    css={classes.icon}
                    role="button"
                    tabIndex={0}
                    aria-hidden="true"
                >
                    <img
                        src={AddressIcon}
                        alt="Center"
                    />
                </div>
                <div css={classes.text}>
                    <Typography
                        variant="subtitle3"
                        css={classes.subHeader}
                    >
                        {t('ADDRESS')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        css={classes.header}
                    >
                        {address}
                    </Typography>
                    <div css={classes.hr} />
                </div>
            </div>
            <div css={classes.row}>
                <div
                    css={classes.icon}
                    role="button"
                    tabIndex={0}
                    aria-hidden="true"
                >
                    <img
                        src={CoordinatesIcon}
                        alt="Center"
                    />
                </div>
                <div css={classes.text}>
                    <Typography
                        variant="subtitle3"
                        css={classes.subHeader}
                    >
                        {t('COORDINATES')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        css={classes.header}
                    >
                        {`${coordinates.lat}, ${coordinates.lng}`}
                    </Typography>
                    <div css={classes.hr} />
                </div>
            </div>
        </div>
    )
}

export default Coordinates
