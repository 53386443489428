import React, {
    useCallback,
} from 'react'
import ReactMUICarousel from 'react-material-ui-carousel'
import CloseIcon from '@src/assets/close_rounded.svg'
import {
    PhotoType,
} from '@src/context/PhotosContext/PhotosContext'

import classes from './FullScreen.style'

const FullScreen = ({
    photos,
    closeFullScreen,
    index,
    setIndex,
}: {
    photos: PhotoType[],
    closeFullScreen: () => void,
    index: number,
    setIndex: React.Dispatch<React.SetStateAction<number>>
}) => {
    const onChange = useCallback((now?: number) => {
        if (now && now <= photos.length - 1) {
            setIndex(now)
        }
    }, [
        photos.length,
        setIndex,
    ])

    return (
        <div css={classes.fullScreenView}>
            <ReactMUICarousel
                autoPlay={false}
                fullHeightHover={false}
                index={index}
                onChange={onChange}
                strictIndexing
                indicatorContainerProps={{
                    style: {
                        bottom: '5%',
                        position: 'fixed',
                        zIndex: 2,
                    },
                }}
            >
                {photos.map((photo, i) => {
                    return (
                        <div
                            key={`photo${i}`} // eslint-disable-line react/no-array-index-key
                        >
                            <img
                                css={classes.image}
                                src={photo.base64}
                                alt="Can't be rendered"
                            />
                            {
                                closeFullScreen ? (
                                    <div
                                        role="button"
                                        key={`closeFullScreen${i}`} // eslint-disable-line react/no-array-index-key
                                        tabIndex={0}
                                        css={classes.close}
                                        onClick={closeFullScreen}
                                        onKeyDown={closeFullScreen}
                                    >
                                        <img
                                            src={CloseIcon}
                                            alt="Close"
                                        />
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                })}
            </ReactMUICarousel>
        </div>
    )
}

export default FullScreen
