import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import APP_URLS from '@src/constants/AppUrls'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'
import TakePhoto from './components/TakePhoto'

function TakePhotoPage(): JSX.Element {
    const {
        addPhoto,
    } = usePhotoContext()
    const navigate = useNavigate()
    const handleBackClick = useCallback(() => {
        navigate(APP_URLS.takePhotoOverviewEmpty)
    }, [navigate])

    const handleTakeClick = useCallback(
        (e: { url: string }) => {
            addPhoto(e.url)
        },
        [addPhoto],
    )

    const handleProceedClick = useCallback(() => {
        return navigate(APP_URLS.takePhotoOverview)
    }, [navigate])

    return (
        <TakePhoto
            onTakeClick={handleTakeClick}
            onBackClick={handleBackClick}
            onProceedClick={handleProceedClick}
        />
    )
}

export default TakePhotoPage
