import {
    css,
} from '@emotion/react'

const classes = {
    fullScreenView: css`
        width: 100vw;
        height: 100vh;
        background: black;
        position: fixed;
        top: 0;
        left: 0;
    `,
    close: css`
        position: absolute;
        top: 15px;
        right: 15px;
    `,
    image: css`
        width: 100%;
    `,
}

export default classes
