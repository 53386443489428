import React, {
    ReactNode,
} from 'react'
import {
    useJsApiLoader,
} from '@react-google-maps/api'

import Loading from '@src/shared-components/Loading'

import {
    Library,
} from './googlemaps.types'

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env

const libraries: Library[] = [
    Library.Core,
    Library.Markers,
    Library.Maps,
    Library.Geocoding,
    Library.Places,
]

function MapApiProvider({
    children,
}:{
    children?: JSX.Element | JSX.Element[] | ReactNode
}): JSX.Element {
    const {
        isLoaded,
    } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleMapKey as string,
        libraries,
    })

    if (!isLoaded) return (<Loading isLoading />)

    return (
        <div>
            {children}
        </div>
    )
}

export default MapApiProvider
