import useAuthContext from '@src/hooks/useAuthContext'

const useJWTToken = () => {
    const {
        token,
    } = useAuthContext()

    return token
}

export default useJWTToken
