import React, {
    useCallback,
    useState,
} from 'react'
import {
    AutoMap,
} from '@src/shared-components/MapApiProvider'
import Button from '@src/shared-components/Button'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup/MainPopup'
import {
    useLocationContext,
} from '@src/context/LocationContext'
import useTimeout from '@src/hooks/useTimeout/useTimeout'

import classes from './LocationConfirmationPage.style'

function LocationConfirmationPage({
    onConfirmClick,
    onManualClick,
}: {
    onConfirmClick: ()=>void,
    onManualClick: ()=>void,
}): JSX.Element {
    const {
        timeout,
    } = useTimeout()
    const {
        setLatLng,
    } = useLocationContext()
    const {
        t,
    } = useT()
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)
    const [
        footerHeight,
        setFooterHeight,
    ] = useState(0)
    const [
        selectedCoordinates,
        setSelectedCoordinates,
    ] = useState({
        lat: 0,
        lng: 0,
    })

    const onConfirmLocal = useCallback(() => {
        setLatLng({
            latitude: selectedCoordinates.lat,
            longitude: selectedCoordinates.lng,
        })
        timeout(onConfirmClick)
    }, [
        onConfirmClick,
        selectedCoordinates,
        setLatLng,
        timeout,
    ])

    return (
        <Layout
            getFooterHeight={setFooterHeight}
            header={(
                <Header
                    title={t('LOCATION')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <Typography
                        variant="subtitle1"
                        css={classes.header}
                    >
                        {t('CONFIRM_LOCATION')}
                    </Typography>
                    <Typography
                        variant="subtitle3"
                        css={classes.subHeader}
                    >
                        {t('CONFIRM_LOCATION_SUB_HEADER_1')}
                        {t('CONFIRM_LOCATION_SUB_HEADER_2')}
                    </Typography>
                    <Button
                        variant="primary-outlined"
                        onClick={onManualClick}
                    >
                        {t('MANUAL')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onConfirmLocal}
                    >
                        {t('CONFIRM')}
                    </Button>
                </>
            )}
        >
            <div>
                <MainPopup
                    isOpen={isPopupOpened}
                    onClose={() => {
                        return showMainPopup(false)
                    }}
                />
                <AutoMap
                    footerHeight={footerHeight}
                    setSelectedCoordinates={setSelectedCoordinates}
                />
            </div>
        </Layout>
    )
}

export default LocationConfirmationPage
