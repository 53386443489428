import React, {
    useEffect,
} from 'react'

import axios from 'axios'
import {
    useTranslation,
} from 'react-i18next'

import apiHooks from './api/hooks/apiHooks'
import RenderRoutes from './shared-components/RenderRoutes/RenderRoutes'
import Loading from './shared-components/Loading/Loading'

function App() {
    const {
        i18n,
    } = useTranslation()

    const {
        data,
        isLoading,
    } = apiHooks.terms.useHasAcceptedTerms()

    useEffect(() => {
        if (i18n.isInitialized) {
            const savedLanguage = localStorage.getItem('LANGUAGE')
            const appLanguage = savedLanguage || navigator.language || 'en'

            i18n.changeLanguage(appLanguage.toLowerCase())
        }
    }, [i18n])

    useEffect(() => {
        axios.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                const url: string = error?.toJSON()?.config?.url || ''

                if (
                    error?.response?.status === 403
          && !url.includes('contact/self')
          && !url.includes('token')
                ) {
                    // navigate('/no-access-rights');
                }
                return Promise.reject(error)
            },
        )
    }, [])

    return isLoading ? <Loading isLoading />
        : <RenderRoutes hasAcceptedTerms={data?.hasSignedTcAndGuide} />
}

export default App
