import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        color: red;
        font-size: 14x;
        padding-top: 4px;
    `,
}

export default classes
