import {
    useTranslation,
} from 'react-i18next'
import React, {
    ReactNode,
} from 'react'
import {
    css,
} from '@emotion/react'
import IS_DEBUG from '@src/constants/isDebug'
import EN_TRANSLATION from '@src/../public/locales/en/translation.json'
import EN_TERMS_CONDITIONS from '@src/../public/locales/en/termsConditions.json'

const KEYS = Object.keys(EN_TRANSLATION)
const TERMS_CONDITIONS_KEYS = Object.keys(EN_TERMS_CONDITIONS)
const TERMS_CONDITIONS_PREFIX = 'termsConditions:'

export type TFn = (key: string, param?: object) => ReactNode
type Return = {
    t: TFn
}

const accountedKey = (key: string): boolean => {
    return KEYS.includes(key)
        || ((key.startsWith(TERMS_CONDITIONS_PREFIX) && TERMS_CONDITIONS_KEYS.includes(key.replace(TERMS_CONDITIONS_PREFIX, ''))))
}

const useT = (): Return => {
    const {
        t,
    } = useTranslation()

    return {
        t: (key, param) => {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return IS_DEBUG && !accountedKey(key) ? <span css={css('color:red')}>{t(key, param) as any}</span> : <>{t(key, param)}</>
        },
    }
}

export default useT
