import {
    css,
} from '@emotion/react'
import {
    TVariant,
} from './Typography'

const classes = {
    root: ({
        variant,
    }: { variant?: TVariant }) => {
        return [
            css``,
            variant === 'headline4'
                && css`
                    font-family: Roboto;
                    font-size: 34px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px; /* 105.882% */
                `,
            variant === 'headline5'
                && css`
                    font-family: Roboto;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 100% */
                    letter-spacing: 0.18px;
                `,

            variant === 'subtitle1'
                && css`
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.15px;
                `,
            variant === 'body2'
                && css`
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: 0.25px;
                `,
            variant === 'subtitle2'
                && css`
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 171.429% */
                    letter-spacing: 0.1px;
                `,

            variant === 'subtitle3'
                && css`
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 14px; 
                  letter-spacing: 0.4px;
                `,

            variant === 'label'
                && css`
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 19px; /* 135.714% */
                    letter-spacing: -0.28px;
                `,
        ]
    },
}

export default classes
