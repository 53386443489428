import {
    createTheme,
} from '@mui/material/styles'

const customMuiAutocompleteTheme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    borderColor: 'red',
                },
                listbox: {
                    '.MuiAutocomplete-option': {
                        paddingTop: '1px',
                        paddingBottom: '0px',
                        paddingLeft: '8px',
                    },
                },
            },
        },
    },
})

export default customMuiAutocompleteTheme
