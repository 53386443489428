import {
    UseQueryResult, useQuery,
} from '@tanstack/react-query'
import axios from 'axios'
import {
    UseQueryOptions,
} from '@src/utils/dataTypes'
import API_URLS
    from '@src/constants/ApiUrls'
import
useGetAxiosConfig
    from './useGetAxiosConfig'

export interface ILocationAreaModel {
    id: number
    areaName: string
    latitude: number
    longitude: number
}
export type ILocationAreaResponse = ILocationAreaModel[]
const useGetLocationArea = (
    locationId: number,
    options: UseQueryOptions,
): UseQueryResult<ILocationAreaResponse, unknown> => {
    const config = useGetAxiosConfig()

    return useQuery<unknown, unknown, ILocationAreaResponse>(
        {
            queryFn: () => {
                return axios.get(API_URLS.getLocationAreaUrl(locationId), config).then((data) => {
                    return data.data
                })
            },
            staleTime: 60 * 1000,
            ...options,
            queryKey: [
                `useGetLocationArea-${locationId}`,
                locationId,
            ],
        },
    )
}

export default useGetLocationArea
