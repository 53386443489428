import {
    useEffect,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import APP_URLS from '@src/constants/AppUrls'
import {
    IGatewayByIdResponse,
} from '@src/api/hooks/useGetGatewayById'
import {
    ISetupState,
} from '@src/hooks/useSetup/useSetup'

export const storeGateWayScannedData = ({
    setState,
    navigate,
    gateway,
    gatewayByIdData,
}: {
        setState: (callback: (prev: ISetupState) => ISetupState, postcallback: () => void) => void,
        navigate: (url: string) => void,
        gateway?: string,
        gatewayByIdData?: IGatewayByIdResponse,
}) => {
    if (gatewayByIdData) {
        setState(
            (prev: ISetupState) => {
                return {
                    ...prev,
                    gatewayMac: gateway,
                    gatewayInfo: gatewayByIdData,
                    selectedLocation: gatewayByIdData?.location,
                }
            },
            () => {
                return navigate(APP_URLS.gatewayDetected)
            },
        )
    }
}

export const storeError = ({
    setState,
    navigate,
    gatewayByIdError,
}: {
    setState: (callback: (prev: ISetupState) => ISetupState, postcallback: () => void) => void,
    navigate?: (url: string) => void,
    gatewayByIdError?: unknown,
}) => {
    if (gatewayByIdError) {
        setState(
            (prev: ISetupState) => {
                return {
                    ...prev,
                    gatewayMac: undefined,
                }
            },
            () => {
                if (navigate) {
                    navigate(APP_URLS.scanQRError)
                }
            },
        )
    }
}

const useGatewayScanned = ({
    gatewayByIdData,
    gateway,
    gatewayByIdError,
    setState,
}:{
    gatewayByIdData?: IGatewayByIdResponse,
    gateway?: string,
    gatewayByIdError?: unknown,
    setState: (callback: (prev: ISetupState) => ISetupState, complete?: () => void) => void
}) => {
    const navigate = useNavigate()

    useEffect(() => {
        storeGateWayScannedData({
            setState,
            navigate,
            gateway,
            gatewayByIdData,
        })
        storeError({
            setState,
            navigate,
            gatewayByIdError,
        })
    }, [
        gatewayByIdData,
        gatewayByIdError,
        navigate,
        setState,
        gateway,
    ])
}

export default useGatewayScanned
