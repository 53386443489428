import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 24px;
        text-align: center;
    `,
    logo: css`
        width: 100%;
        height: 100%;
    `,
    header: css`
        margin-top: 2px;
    `,
    subHeader: css`
        margin-top: 14px;
    `,
}

export default classes
