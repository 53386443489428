import {
    ExtendedProfile,
    UserInfo,
} from './Auth.types'

export const TIME_IN_MS = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
}

export const INITIAL_USER_INFO: UserInfo = {
    assignedRoles: [],
    avatar: '',
    id: null as unknown as number,
    companyName: null as unknown as string,
    name: '',
    email: '',
}

export const INITIAL_EXTENDED_PROFILE: ExtendedProfile = {
    assignedRoles: [],
    attributes: {
        companyId: [],
    },
    username: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null as unknown as boolean,
}
