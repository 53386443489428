import React, {
    ReactNode, useCallback, useMemo,
} from 'react'
import {
    IUseFormReturn, FormContext,
} from '@src/hooks/useForm/useForm'
import IS_DEBUG from '@src/constants/isDebug'
import JsonPreview from '../JsonPreview'

type Props = {
    form: IUseFormReturn
    children: ReactNode
    className?: string
}

function Form({
    children, form, className,
}: Props): JSX.Element {
    const renderJsonPreview = (): JSX.Element => {
        const json = {
            values: form,
        }

        return <JsonPreview json={json} />
    }

    const {
        handleSubmit: onSubmit, values,
    } = form
    const handleSubmit = useCallback(
        (e: React.FormEvent) => {
            onSubmit(values)
            e.preventDefault()
        },
        [
            onSubmit,
            values,
        ],
    )

    const value = useMemo(() => {
        return {
            values: form.values as Record<string, unknown>,
            touched: form.touched,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
        }
    }, [
        form.setFieldTouched,
        form.setFieldValue,
        form.touched,
        form.values,
    ])

    return (
        <FormContext.Provider
            value={value}
        >
            <form
                onSubmit={handleSubmit}
                className={className}
            >
                {IS_DEBUG && renderJsonPreview()}
                {children}
            </form>
        </FormContext.Provider>
    )
}

export default Form
