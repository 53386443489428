import React, {
    useCallback,
    useState,
} from 'react'

import ConfirmPage from '../Confirm/ConfirmPage'

import Terms from './components/Terms'

function TermsPage(): JSX.Element {
    const [
        acceptedTerms,
        setAcceptedTerms,
    ] = useState(false)

    const handleAccept = useCallback(() => {
        setAcceptedTerms(true)
    }, [])

    if (acceptedTerms) {
        return (<ConfirmPage />)
    }

    return (<Terms onAccept={handleAccept} />)
}

export default TermsPage
