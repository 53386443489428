export enum Library {
    Core = 'core',
    Maps = 'maps',
    Markers = 'marker',
    Geocoding = 'geocoding',
    Places = 'places',
}

export default {
    Library,
}
