import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
        text-align: center;
    `,
    logo: css`
        width: 100%;
        height: 100%;
    `,
    header: css`
        padding-top: 40px;
    `,
    subHeader: css`
        padding-top: 20px;
    `,
}

export default classes
