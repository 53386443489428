import React, {
    useCallback,
    useEffect, useRef, useState,
} from 'react'
import ReactMUICarousel from 'react-material-ui-carousel'

import {
    PhotoType,
} from '@src/context/PhotosContext/PhotosContext'
import FullScreenIcon from '@src/assets/full_screen.svg'
import DeleteIcon from '@src/assets/delete.svg'

import classes from './Carousel.style'

const defaultProps = {
    removePhoto: undefined,
    setFullScreen: undefined,
    autoPlay: false,
}

const Carousel = ({
    photos,
    removePhoto,
    setFullScreen,
    autoPlay,
    index,
    setIndex,
}: {
    photos: PhotoType[],
    removePhoto?: (i: number) => void,
    setFullScreen?: (i: boolean) => void,
    autoPlay?: boolean,
    index: number,
    setIndex: React.Dispatch<React.SetStateAction<number>>
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [
        height,
        setHeight,
    ] = useState<number>()

    const onChange = useCallback((now?: number) => {
        if (now && now <= photos.length - 1) {
            setIndex(now)
        }
    }, [
        photos.length,
        setIndex,
    ])

    useEffect(() => {
        if (index > photos.length - 1) {
            setIndex(photos.length - 1)
        }
    }, [
        photos.length,
        index,
        setIndex,
    ])

    useEffect(() => {
        if (ref?.current?.clientHeight) {
            setHeight(ref?.current?.clientHeight)
        }
    }, [ref?.current?.clientHeight])

    return (
        <div
            css={classes.container}
            ref={ref}
        >
            { height ? (
                <ReactMUICarousel
                    fullHeightHover={false}
                    autoPlay={autoPlay}
                    index={index}
                    onChange={onChange}
                    strictIndexing
                >
                    {photos.map((photo, i) => {
                        const removePhotoHandler = () => {
                            return removePhoto && removePhoto(i)
                        }

                        const setFullScreenView = () => {
                            return setFullScreen && setFullScreen(true)
                        }

                        return (
                            <div
                                key={`photo${i}`} // eslint-disable-line react/no-array-index-key
                                css={classes.previewImg}
                                style={{
                                    background: `url(${photo.base64}) no-repeat`,
                                    objectFit: 'cover',
                                    height: `${height - 40}px`,
                                }}
                            >
                                {setFullScreen ? (
                                    <div
                                        role="button"
                                        key={`fullScreenView${i}`} // eslint-disable-line react/no-array-index-key
                                        tabIndex={0}
                                        css={classes.icon()}
                                        onClick={setFullScreenView}
                                        onKeyDown={setFullScreenView}
                                    >
                                        <img
                                            src={FullScreenIcon}
                                            alt="Full Screen View"

                                        />
                                    </div>
                                ) : null}
                                {removePhoto ? (
                                    <div
                                        role="button"
                                        key={`removePhoto${i}`} // eslint-disable-line react/no-array-index-key
                                        tabIndex={0}
                                        css={classes.icon(true)}
                                        onClick={removePhotoHandler}
                                        onKeyDown={removePhotoHandler}
                                    >
                                        <img
                                            src={DeleteIcon}
                                            alt="Delete"
                                        />
                                    </div>
                                ) : null}
                            </div>

                        )
                    })}
                </ReactMUICarousel>
            ) : null }
        </div>
    )
}

Carousel.defaultProps = defaultProps

export default Carousel
