import React, {
    ReactNode,
} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import classes from './ConfirmDialog.style'

type ConfirmDialogProps = {
    open: boolean,
    handleClose: (close: boolean) => void,
    dialogTitle?: string,
    dialogContent?: string,
    positiveLabel?: string,
    positiveLabelDisabled?: boolean,
    negativeLabel?: string,
    children?: ReactNode,
}

const defaultProps = {
    dialogTitle: '',
    dialogContent: '',
    positiveLabel: 'yes',
    positiveLabelDisabled: undefined,
    negativeLabel: 'no',
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
    const {
        open,
        handleClose,
        dialogTitle,
        dialogContent,
        positiveLabel,
        positiveLabelDisabled,
        negativeLabel,
        children,
    } = props

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onClick={(event) => { event.stopPropagation() }}
        >
            <DialogTitle css={classes.title}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                {dialogContent && (
                    <DialogContentText css={classes.message}>
                        {dialogContent}
                    </DialogContentText>
                )}
                {children}
            </DialogContent>
            <DialogActions>
                <button
                    type="button"
                    name="cancel"
                    onClick={() => { handleClose(false) }}
                    css={classes.button({
                        variant: 'cancel',
                    })}
                >
                    <div>{negativeLabel}</div>
                </button>
                <button
                    type="button"
                    name="ok"
                    onClick={() => { handleClose(true) }}
                    disabled={positiveLabelDisabled}
                    css={classes.button({
                        variant: 'delete',
                    })}
                >
                    <div>{positiveLabel}</div>
                </button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmDialog.defaultProps = defaultProps

export default ConfirmDialog
