import React, {
    useCallback,
    useState,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import Button from '@src/shared-components/Button'
import useT from '@src/hooks/useT'
import classes from './ChangeLanguageMenu.style'

const LANGUAGES = [
    {
        label: 'ENGLISH', value: 'EN',
    },
    {
        label: 'GERMAN', value: 'DE',
    },
    {
        label: 'DUTCH', value: 'NL',
    },
    {
        label: 'FRENCH', value: 'FR',
    },
]

type Props = {
    onClose?: () => void
}

const ChangeLanguageMenu = ({
    onClose,
}: Props) => {
    const {
        i18n,
    } = useTranslation()
    const {
        t,
    } = useT()

    const [
        selectedLanguage,
        setSelectedLanguage,
    ] = useState(() => {
        const savedLanguage = localStorage.getItem('LANGUAGE')

        return savedLanguage
    })

    const [
        isOpen,
        setIsOpen,
    ] = useState(false)

    const handleChangeLanguageClick = useCallback((language: string) => {
        localStorage.setItem('LANGUAGE', language)
        setSelectedLanguage(language)
        setIsOpen(false)
        i18n.changeLanguage(language.toLowerCase())
        if (onClose) {
            onClose()
        }
    }, [
        i18n,
        onClose,
    ])

    const handleOpenMenuClick = useCallback(() => {
        setIsOpen(!isOpen)
    }, [isOpen])

    return (
        <>
            <Button
                variant="quaternary"
                onClick={handleOpenMenuClick}
            >
                {t('CHANGE_LANGUAGE')}
            </Button>
            { isOpen && (
                LANGUAGES.map(({
                    label, value,
                }) => {
                    return (
                        <Button
                            variant="quaternary"
                            key={value}
                            onClick={() => { return handleChangeLanguageClick(value) }}
                        >
                            <span css={value === selectedLanguage && classes.selected}>
                                {label}
                            </span>
                        </Button>
                    )
                })
            )}
        </>
    )
}

export default ChangeLanguageMenu
