import React, {
    ReactNode,
    useCallback,
    useRef,
} from 'react'
import useResizeObserver from '@react-hook/resize-observer'

import classes from './Layout.style'

type Props = {
    children: ReactNode
    className?: string
    header?: ReactNode
    footer?: ReactNode
    getFooterHeight?: (value: number) => void
}

function Layout({
    children,
    header,
    footer,
    className,
    getFooterHeight,
}: Props): JSX.Element {
    const footerContainerRef = useRef<HTMLDivElement>(null)

    const resizeObserverCallback = useCallback((entry: {
        contentRect: {
            height: number,
        },
    }) => {
        if (getFooterHeight) {
            getFooterHeight(Math.round(entry.contentRect.height))
        }
    }, [getFooterHeight])

    useResizeObserver(footerContainerRef, resizeObserverCallback)

    return (
        <div
            className={className}
            css={classes.root}
        >
            {header && <div css={classes.header}>{header}</div>}
            <div css={classes.content}>{children}</div>
            {footer && (
                <div
                    css={classes.footerCnt}
                    ref={footerContainerRef}
                >
                    <div css={classes.footer}>{footer}</div>
                </div>
            )}
        </div>
    )
}

export default Layout
