import {
    css,
} from '@emotion/react'

const classes = {
    blockPanel: (isOpen?: boolean) => {
        return [
            css`
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                overflow: hidden;
                position: absolute;
                display: none;
                background-color: gray;
                opacity: 0.5;
                z-index: 10;
            `,
            isOpen
                && css`
                    display: block;
                `,
        ]
    },
    popup: (isOpen?: boolean) => {
        return [
            css`
                width: 100%;
                height: 100%;
                top: 0;
                left: -100%;
                overflow: hidden;
                position: absolute;
                z-index: 11;
                opacity: 0;
                transition: all 0.25s;
                -webkit-transition: all 0.25s;
            `,
            isOpen
                && css`
                    opacity: 1;
                    left: 0;
                `,
        ]
    },
    header: css`
        display: flex;
        justify-content: flex-end;
        width: 100%;
    `,
    closeIconCnt: css`
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(235, 235, 245, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 16px;
        cursor: pointer;
    `,
    closeIcon: css`
        width: 16px;
        height: 16px;
    `,
}

export default classes
