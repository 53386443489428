import React, {
    ReactNode,
} from 'react'
import {
    Link as RouterLink,
} from 'react-router-dom'
import classes from './Link.style'

type Props = {
    href: string
    children: ReactNode
    className?: string
}

function Link({
    children, href, className,
}: Props): JSX.Element {
    return (
        <RouterLink
            css={classes.root}
            className={className}
            to={href}
        >
            {children}
        </RouterLink>
    )
}

export default Link
