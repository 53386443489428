import React, {
    useCallback, useMemo,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import ErrorWithSupportPage from '@src/pages/ScanQRError/ErrorWithSupportPage'
import useSetup from '@src/hooks/useSetup/useSetup'
import consoleService from '@src/services/consoleService'
import openSupport from '@src/utils/jiraHelpDesk'
import useCheckGatewayId from '@src/api/hooks/useCheckGatewayId'
import {
    GatewayStatus,
} from '@src/types/enums'
import {
    storeGateWayScannedData,
    storeError,
} from '@src/hooks/useGatewayScanned/useGatewayScanned'
import {
    IGatewayByIdResponse,
} from '@src/api/hooks/useGetGatewayById'
import useT from '@src/hooks/useT/useT'

import parseGatewayQRCode from './parseGatewayQRCode'
import ScanQR from './components/ScanQR'

function ScanQRPage(): JSX.Element {
    const [
        state,
        setState,
    ] = useSetup()
    const navigate = useNavigate()
    const {
        t,
    } = useT()

    const handleOnSupportClick = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const onSuccess = useCallback((data: IGatewayByIdResponse) => {
        storeGateWayScannedData({
            setState,
            navigate,
            gatewayByIdData: (data && data.gatewayStatus !== GatewayStatus.INSTALLED)
                ? data : undefined,
            gateway: String(state.gatewayMac),
        })
    }, [
        navigate,
        setState,
        state.gatewayMac,
    ])
    const onError = useCallback((errorResponse: unknown) => {
        storeError({
            setState,
            gatewayByIdError: errorResponse,
        })
    }, [setState])

    const {
        mutate: checkGatewayId,
        reset,
        error,
        data: gatewayByIdData,
    } = useCheckGatewayId(onSuccess, onError)
    const validGateWayData = useMemo(() => {
        return gatewayByIdData && gatewayByIdData.gatewayStatus !== GatewayStatus.INSTALLED
    }, [gatewayByIdData])

    const handleScanSuccess = useCallback(
        (value: string) => {
            const parsedCode = parseGatewayQRCode(value)

            setState((prev) => {
                return {
                    ...prev,
                    gatewayMac: parsedCode ?? value,
                    gatewayInfo: undefined,
                    selectedLocation: undefined,
                }
            })
            checkGatewayId({
                id: parsedCode ?? value,
            })
        },
        [
            checkGatewayId,
            setState,
        ],
    )

    const onErrorBack = useCallback(() => {
        reset()
    }, [reset])

    if (error || (gatewayByIdData && !validGateWayData)) {
        return (
            <ErrorWithSupportPage
                message={t('GATEWAY_NOT_FOUNT_ERROR')}
                header={t('GATEWAY_NOT_FOUNT_HEADER')}
                onBackClick={onErrorBack}
            />
        )
    }

    return (
        <ScanQR
            onSupportClick={handleOnSupportClick}
            onScanSuccess={handleScanSuccess}
        />
    )
}

export default ScanQRPage
