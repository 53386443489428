export interface IError {
  message?: string;
  isAxiosError?: boolean;
  response: {
    data: { message: string }[];
  };
}
const parseErrorMessage = (error: IError) => {
    return error.isAxiosError && error.response?.data?.length
        ? `${error.message}: ${error.response.data[0].message}`
        : error.message
}

export default parseErrorMessage
