import {
    css,
} from '@emotion/react'

const classes = {
    selected: css`
        color: #00739A;  
        font-weight: 700;
    `,
}

export default classes
