import React, {
    useCallback,
    useState,
} from 'react'
import {
    ManualMap,
} from '@src/shared-components/MapApiProvider'
import Button from '@src/shared-components/Button'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup/MainPopup'
import {
    useLocationContext,
} from '@src/context/LocationContext'
import useTimeout from '@src/hooks/useTimeout/useTimeout'
import Coordinates from './Coordinates'

import classes from './LocationConfirmationPage.style'

function ManualLocationConfirmationPage({
    onConfirmClick,
    onAutoClick,
    noAutoLocation,
}: {
    onConfirmClick: ()=>void,
    onAutoClick: ()=>void,
    noAutoLocation: boolean,
}): JSX.Element {
    const {
        timeout,
    } = useTimeout()
    const {
        setLatLng,
    } = useLocationContext()
    const {
        t,
    } = useT()
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)
    const [
        footerHeight,
        setFooterHeight,
    ] = useState(0)
    const [
        isConfirmMode,
        setIsConfirmMode,
    ] = useState<boolean>(false)
    const [
        selectedCoordinates,
        setSelectedCoordinates,
    ] = useState<google.maps.LatLngLiteral>({
        lat: 0,
        lng: 0,
    })
    const [
        currentAddress,
        setCurrentAddress,
    ] = useState('')

    const getAddress = useCallback(({
        lat,
        lng,
    }: google.maps.LatLngLiteral) => {
        const geocoder = new google.maps.Geocoder()

        geocoder
            .geocode({
                location: {
                    lat, lng,
                },
            })
            .then((response) => {
                const filteredRespoce = response.results.filter((item) => {
                    return item.types.some((type) => {
                        return type === 'street_address'
                    })
                })

                if (filteredRespoce.length > 0) {
                    setCurrentAddress(filteredRespoce[0].formatted_address)
                }
            })
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.log(`Geocoder failed due to: ${e}`)
            })
    }, [])

    const onReadyClick = useCallback(() => {
        setIsConfirmMode(true)
        getAddress(selectedCoordinates)
    }, [
        selectedCoordinates,
        getAddress,
    ])

    const onConfirmLocal = useCallback(() => {
        setLatLng({
            latitude: selectedCoordinates.lat,
            longitude: selectedCoordinates.lng,
        })
        timeout(onConfirmClick)
    }, [
        setLatLng,
        onConfirmClick,
        timeout,
        selectedCoordinates.lat,
        selectedCoordinates.lng,
    ])

    const switchToReadyMode = useCallback(() => {
        setIsConfirmMode(false)
    }, [])

    return (
        <Layout
            getFooterHeight={setFooterHeight}
            header={(
                <Header
                    title={t('LOCATION')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <Typography
                        variant="subtitle1"
                        css={classes.header}
                    >
                        {isConfirmMode ? t('CONFIRM_LOCATION') : t('MANUAL_MAP_HEADER')}
                    </Typography>
                    <Typography
                        variant="subtitle3"
                        css={classes.subHeader}
                    >
                        { isConfirmMode ? t('CONFIRM_LOCATION_SUB_HEADER_1') : t('MANUAL_MAP_SUB_HEADER')}
                    </Typography>
                    { isConfirmMode && (
                        <Coordinates
                            address={currentAddress}
                            coordinates={selectedCoordinates}
                        />
                    )}
                    { !isConfirmMode && (
                        <Button
                            variant="primary"
                            onClick={onReadyClick}
                        >
                            {t('READY')}
                        </Button>
                    )}
                    { isConfirmMode && (
                        <Button
                            variant="primary"
                            onClick={onConfirmLocal}
                        >
                            {t('CONFIRM')}
                        </Button>
                    )}
                </>
            )}
        >
            <div>
                <MainPopup
                    isOpen={isPopupOpened}
                    onClose={() => {
                        return showMainPopup(false)
                    }}
                />
                <ManualMap
                    footerHeight={footerHeight}
                    onAutoModeClick={noAutoLocation ? undefined : onAutoClick}
                    setSelectedCoordinates={setSelectedCoordinates}
                    draggable={!isConfirmMode}
                    switchToReadyMode={switchToReadyMode}
                />
            </div>
        </Layout>
    )
}

export default ManualLocationConfirmationPage
