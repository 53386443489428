import {
    css,
} from '@emotion/react'

const classes = {
    search: css`
      position: absolute;
      top: 5px;
      left: 5px;
      width: calc(100% - 10px);
      box-sizing: border-box;
      height: 64px;
      padding: 0 20px;
      border-radius: 10px;
      font-size: 16px;
      line-height: 24px;
      background: white;
      outline: none;
      font-family: Roboto;
      letter-spacing: 0.5px;
    `,
}

export default classes
