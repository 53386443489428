import React, {
    useState,
} from 'react'
import Header from '@src/Layout/components/Header'
import Layout from '@src/Layout'
import MainPopup from '@src/MainPopup'
import WebCam from '@src/shared-components/WebCam/WebCam'
import useT from '@src/hooks/useT/useT'
import classes from './TakePhoto.style'

type Props = {
    onTakeClick: (e: { url: string }) => void,
    onBackClick: () => void,
    onProceedClick: () => void,
}

function TakePhoto({
    onTakeClick,
    onBackClick,
    onProceedClick,
}: Props): JSX.Element {
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)
    const {
        t,
    } = useT()

    return (
        <Layout
            header={(
                <Header
                    title={t('INSTALL_GATEWAY')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
        >
            <div css={classes.root}>
                <MainPopup
                    isOpen={isPopupOpened}
                    onClose={() => {
                        return showMainPopup(false)
                    }}
                />
                <WebCam
                    onBackClick={onBackClick}
                    onCaptured={onTakeClick}
                    onProceedClick={onProceedClick}
                />
            </div>
        </Layout>
    )
}

export default TakePhoto
