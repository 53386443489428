import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import dictionary from '@src/dictionary'
import APP_URLS from '@src/constants/AppUrls'
import Loading from '@src/shared-components/Loading'
import useT from '@src/hooks/useT/useT'
import useCheckAreaAvailability from '@src/api/hooks/useCheckAreaAvailability'
import useGetLocationArea, {
    ILocationAreaModel,
} from '@src/api/hooks/useGetLocationArea'
import useSetup from '@src/hooks/useSetup/useSetup'
import useGetLocations from '@src/api/hooks/useGetLocations'
import {
    ILocationModel,
} from '@src/api/hooks/useGetGatewayById'
import useHasPermission, {
    AUTH_ROLES,
} from '@src/api/hooks/useHasPermission'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'

import ErrorWithSupportPage from '../ScanQRError/ErrorWithSupportPage'
import GatewayDetected from './components/GatewayDetected'

function GatewayDetectedPage(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        cleanUp: cleanUpSavedPhotos,
    } = usePhotoContext()
    const navigate = useNavigate()
    const [
        storedState,
        setStoredState,
    ] = useSetup()
    const {
        data: areaData,
        isLoading: areaIsLoading,
        error: areaError,
    } = useGetLocationArea(storedState.selectedLocation?.id ?? 0, {
        enabled: !!storedState.selectedLocation,
    })

    const handleOnAreaAvailabilityCheckSuccess = useCallback(() => {
        navigate(APP_URLS.installGateway)
    }, [navigate])

    const {
        mutate: checkAreaAvailabilityMutate,
        isPending: confirmcheckAreaAvailabilityPending,
        error: checkAreaAvailabilityError,
        reset: resetcheckAreaAvailabilityError,
    } = useCheckAreaAvailability(handleOnAreaAvailabilityCheckSuccess)

    const hasAdvancedPermission = useHasPermission(AUTH_ROLES.SGA_USER_ADVANCED)
    const [
        locationSearchValue,
        setLocationSearchValue,
    ] = useState<string | undefined>(undefined)

    const {
        data: locationsData,
        isLoading: locationsIsLoading,
    } = useGetLocations({
        enabled: hasAdvancedPermission,
        searchAll: locationSearchValue,
    })

    const isLoading = useMemo(() => {
        return areaIsLoading || confirmcheckAreaAvailabilityPending
    }, [
        areaIsLoading,
        confirmcheckAreaAvailabilityPending,
    ])

    const error = useMemo(() => {
        return areaError || checkAreaAvailabilityError
    }, [
        areaError,
        checkAreaAvailabilityError,
    ])

    const initOptions = useMemo(() => {
        return storedState.gatewayInfo?.location
            ? dictionary.formatLocationsOptions([storedState.gatewayInfo?.location])
            : []
    }, [storedState.gatewayInfo?.location])

    const locationOptions = useMemo(() => {
        if (hasAdvancedPermission) {
            return locationSearchValue === undefined
                ? initOptions
                : dictionary.formatLocationsOptions(locationsData)
        }
        return dictionary.formatLocationOptions(storedState.gatewayInfo?.location)
    }, [
        hasAdvancedPermission,
        initOptions,
        locationSearchValue,
        locationsData,
        storedState.gatewayInfo?.location,
    ])

    const areaOptions = dictionary
        .useGetLocationAreaOptions(storedState.selectedLocation?.id ? areaData : [])

    const handleNextClick = useCallback(() => {
        checkAreaAvailabilityMutate({
            areaId: storedState.selectedArea?.id ?? 0,
        })
    }, [
        checkAreaAvailabilityMutate,
        storedState.selectedArea,
    ])

    const handleBackClick = useCallback(() => {
        resetcheckAreaAvailabilityError()
        setStoredState(
            (prev) => {
                return {
                    ...prev,
                    gatewayMac: undefined,
                    gatewayInfo: undefined,
                    selectedLocation: undefined,
                    selectedArea: undefined,
                }
            },
            () => { return navigate(APP_URLS.scanQR) },
        )
    }, [
        setStoredState,
        navigate,
        resetcheckAreaAvailabilityError,
    ])

    const onErrorBack = useCallback(() => {
        resetcheckAreaAvailabilityError()
    }, [resetcheckAreaAvailabilityError])

    const handleAreaChange = useCallback(
        (data?: ILocationAreaModel) => {
            setStoredState((prev) => {
                return {
                    ...prev, selectedArea: data,
                }
            })
        },
        [setStoredState],
    )

    const handleAirportChange = useCallback(
        (data?: ILocationModel) => {
            setStoredState((prev) => {
                return {
                    ...prev, selectedLocation: data,
                }
            })
        },
        [setStoredState],
    )

    useEffect(() => {
        cleanUpSavedPhotos()
    }, [cleanUpSavedPhotos])

    if (error) {
        return (
            <ErrorWithSupportPage
                message={t('AREA_NOT_AVAILABILITY_MESSAGE')}
                onBackClick={onErrorBack}
            />
        )
    }

    return (
        <Loading isLoading={isLoading}>
            { !locationsIsLoading && (
                <GatewayDetected
                    selectedLocationId={storedState.selectedLocation?.id?.toString() ?? ''}
                    selectedAreaId={storedState.selectedArea?.id?.toString() ?? ''}
                    onAirportChange={handleAirportChange}
                    onAreaChange={handleAreaChange}
                    locationOptions={locationOptions}
                    areaOptions={areaOptions}
                    gatewayNumber={storedState?.gatewayMac}
                    onNextClick={handleNextClick}
                    onBackClick={handleBackClick}
                    locationEnabled={hasAdvancedPermission}
                    setLocationSearchValue={setLocationSearchValue}
                />
            )}
        </Loading>
    )
}

export default GatewayDetectedPage
