import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        font-size: 12px;
        position: relative;
        caret-color: transparent;
    `,
    button: css`
        color: red;
        border: 1px solid red;
        display: inline;
        padding: 2px;
        cursor: pointer;
        position: absolute;
    `,
    preview: css`
        border: 1px solid red;
        overflow-y: auto;
        max-height: 300px;
        margin-top: 1px;
    `,
}

export default classes
