import React from 'react'

import LocatioContextProvider from '@src/context/LocationContext'

import LocationConfirmation from './LocationConfirmation'

function LocationConfirmationContainer(): JSX.Element {
    return (
        <LocatioContextProvider>
            <LocationConfirmation />
        </LocatioContextProvider>
    )
}

export default LocationConfirmationContainer
