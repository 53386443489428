import {
    css,
} from '@emotion/react'

const classes = {
    header: css`
        padding: 32px 24px;
        text-align: center;
    `,

    root: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    `,
}

export default classes
