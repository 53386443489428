import {
    css,
} from '@emotion/react'
import THEME from '@src/constants/Theme'

const classes = {
    title: css`
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #000000DE;
          padding-top: 30px;
    `,
    message: css`
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #545454;
    `,
    button: ({
        variant,
    }: { variant?: 'cancel'|'delete' }) => {
        return [
            css`
                cursor: pointer;
                border-radius: 8px;
                display: flex;
                height: 48px;
                padding: 6px 6px 6px 8px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                background-color: white;
                border: 1px solid white;

                div {
                    display: flex;
                    padding: 4px 8px 4px 6px;
                    align-items: flex-start;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 1.25px;
                    text-transform: uppercase;
                }
            `,
            variant === 'cancel'
            && css`
                    color: ${THEME.skycell_dark};
                `,

            variant === 'delete'
            && css`
                    color:  #B00020;
                `,
        ]
    },
}

export default classes
