//! !! Forked from https://github.com/jamenamcinteer/react-qr-barcode-scanner (does not work with react 18.2.0, need upgrade react-webcam to last version or downgrade react to 17.0.2)

import React from 'react'
import {
    BrowserMultiFormatReader, Result,
} from '@zxing/library'
import ReactWebcam from 'react-webcam'

type SupportedConstrainWithTorch = { torch: boolean }
function BarcodeScannerComponent({
    onUpdate,
    onError,
    width = '100%',
    height = '100%',
    facingMode = 'environment',
    torch,
    delay = 500,
    videoConstraints,
    stopStream,
    className,
}: {
    onUpdate: (arg0: unknown, arg1?: Result) => void
    onError?: (arg0: string | DOMException) => void
    width?: number | string
    height?: number | string
    facingMode?: 'environment' | 'user'
    torch?: boolean
    delay?: number
    videoConstraints?: MediaTrackConstraints
    stopStream?: boolean
    className?: string
}): React.ReactElement {
    const webcamRef = React.useRef<ReactWebcam>(null)

    const capture = React.useCallback(() => {
        const codeReader = new BrowserMultiFormatReader()
        const imageSrc = webcamRef?.current?.getScreenshot()

        if (imageSrc) {
            codeReader
                .decodeFromImage(undefined, imageSrc)
                .then((result) => {
                    onUpdate(null, result)
                })
                .catch((err) => {
                    onUpdate(err)
                })
        }
    }, [onUpdate])

    React.useEffect(() => {
        // Turn on the flashlight if prop is defined and device has the capability
        if (
            typeof torch === 'boolean'
              && (navigator?.mediaDevices?.getSupportedConstraints() as
                unknown as SupportedConstrainWithTorch).torch
        ) {
            const stream = webcamRef?.current?.video?.srcObject as MediaStream

            if (stream) {
                const [track] = stream.getVideoTracks() // get the active track of the stream

                if (track && (track.getCapabilities() as SupportedConstrainWithTorch).torch
                      && !(track.getConstraints() as SupportedConstrainWithTorch).torch) {
                    track
                        .applyConstraints({
                            advanced: [{
                                torch,
                            } as MediaTrackConstraintSet],
                        })
                        .catch((err: unknown) => {
                            return onUpdate(err)
                        })
                }
            }
        }
    }, [
        torch,
        onUpdate,
    ])

    React.useEffect(() => {
        if (stopStream) {
            let stream: MediaStream | null = webcamRef?.current?.video?.srcObject as MediaStream

            if (stream) {
                stream.getTracks().forEach((track:MediaStreamTrack) => {
                    stream?.removeTrack(track)
                    track.stop()
                })
                stream = null
            }
        }
    }, [stopStream])

    React.useEffect(() => {
        const interval = setInterval(capture, delay)

        return () => {
            clearInterval(interval)
        }
    }, [
        capture,
        delay,
    ])

    return (
        <ReactWebcam
            className={className}
            width={width}
            height={height}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={
                videoConstraints || {
                    facingMode,
                }
            }
            audio={false}
            onUserMediaError={onError}
        />
    )
}

export default BarcodeScannerComponent
