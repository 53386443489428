import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        padding: 0px 27px;

        ul {
            padding-left: 20px;
        }
    `,
    header: css`
        text-align: center;
        padding-top: 32px;
        padding-bottom: 16px;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
    `,
    listItemHeader: css`
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: 0.15px;
        counter-increment: item;
        list-style-type: none;
        padding-top: 1em;
        &:before {
            content: counters(item, ".") ". ";
            font-weight: bold;
        };
    `,
    ol: css`
          list-style-type: none;
          counter-reset: item;
          margin: 0;
          padding: 0;
    `,
    li: css`
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
        padding-top: 1em;
        counter-increment: item;        
        &:before {
            content: counters(item, ".") ". ";
            font-weight: bold;
        };
    `,
    ul: css`
        list-style-type: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
        padding-top: 1em;
    `,
    dottedItem: css`
        list-style-type: disc;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
        padding-top: 1em;
    `,
    link: css`
        padding-left: 0.3em;
    `,
    updateDate: css`        
        list-style-type: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
        padding-top: 1em;
        text-align: right;
    `,
}

export default classes
