import THEME from '@src/constants/Theme'

const openSupport = (onError = (): void => {}) => {
    const jiraIframe: HTMLIFrameElement = document.querySelector('iframe[name="JSD widget"]') as HTMLIFrameElement
    const button: HTMLElement = jiraIframe?.contentDocument?.querySelector('#help-button') as HTMLElement

    if (!jiraIframe || !button) {
        onError()
        return
    }
    if (jiraIframe && jiraIframe.contentDocument) {
        let iframeStyle: HTMLStyleElement = jiraIframe.contentDocument.querySelector('#customStyle') as HTMLStyleElement

        if (!iframeStyle) {
            iframeStyle = jiraIframe.contentDocument.body.appendChild(document.createElement('style'))
            iframeStyle.id = 'customStyle'
            iframeStyle.textContent = `#button-container { display: none; } #widget-header {background-color:${THEME.skycell_primary_color}!important;background-image:none;}`
            jiraIframe.style.pointerEvents = 'all'
            jiraIframe.style.opacity = '1'
        }

        button.click()
    }
}

export default openSupport
