import {
    css,
} from '@emotion/react'
import THEME from '@src/constants/Theme'
import {
    EProgressState,
} from './Progress'

const classes = {
    root: css`
        display: flex;
    `,
    itemContainer: css`
        display: flex;
        width: 100%;
    `,
    img: css`
        width: 16px;
        height: 12px;
    `,
    item: (size: number) => {
        return css`
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 0 1 ${size}%;
        `
    },
    line: (visible: boolean) => {
        return [
            css`
                margin-top: 20px;
                border-top: 1px solid ${THEME.skycell_primary_color};
                width: 100%;
                opacity: 0;
            `,
            visible
                && css`
                    opacity: 1;
                `,
        ]
    },
    circle: (state: EProgressState) => {
        return [
            css`
                min-width: 40px;
                min-height: 40px;
                border-radius: 50%;
                border: 1px solid ${THEME.skycell_primary_color};
                text-align: center;
                line-height: 38px;
            `,

            state === EProgressState.default
                && css`
                    color: ${THEME.skycell_primary_color};
                `,
            state === EProgressState.inProgress
                && css`
                    background-color: ${THEME.skycell_primary_color};
                    color: white;
                `,
            state === EProgressState.completed
                && css`
                    color: white;
                    background-color: ${THEME.skycell_primary_color};
                `,
        ]
    },
    label: css`
        padding-top: 8px;
    `,
}

export default classes
