import {
    css,
} from '@emotion/react'

const classes = {
    previewImgEmpty: css`
        display: flex;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
        flex-grow: 1;
    `,
    previewImg: css`
        display: flex;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: auto;
        flex-grow: 1;
    `,
    remove: css`
        width: 20px;
        height: 20px;
    `,
    removeWrapper: css`
        width: 20px;
        height: 20px;
    `,
}

export default classes
