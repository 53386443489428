import ENV from './Env'

const SKYGATE_URL = `${ENV.REACT_APP_SERVER_URL}skycore-backend/v1/skygate/`

const API_URLS = {
    getAttachmentUrl: () => {
        return `${SKYGATE_URL}attachment`
    },
    getConfirmInstallationUrl: (id: number) => {
        return `${SKYGATE_URL}gateway/${id}/installation-confirmation`
    },
    getGatewayByIdUrl: (id: string) => {
        return `${SKYGATE_URL}gateway/${id}`
    },
    getLocationAreaUrl: (locationId: number) => {
        return `${SKYGATE_URL}location/${locationId}/area`
    },
    filterLocationUrl: () => {
        return `${SKYGATE_URL}location/filter-request/all`
    },
    acceptTermsUrl: () => {
        return `${SKYGATE_URL}user/sign-tc-and-guide`
    },
    hasAcceptedTermsUrl: () => {
        return `${SKYGATE_URL}user/has-signed-tc-and-guide`
    },
    checkAreaAvailability: (areaId: number) => {
        return `${SKYGATE_URL}area/${areaId}/availability`
    },
    getRoles: () => {
        return `${ENV.REACT_APP_SERVER_URL}skycore-backend/v1/user/roles`
    },
    useCheckGatewayAvailability: (gatewayMAC: string) => {
        return `${SKYGATE_URL}gateway/${gatewayMAC}`
    },
}

export default API_URLS
