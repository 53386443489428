import {
    UseMutationResult, useMutation,
} from '@tanstack/react-query'
import axios from 'axios'
import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from './useGetAxiosConfig'

export interface IConfirmInstallationRequest {
    latitude: number
    longitude: number
    location: {
        id: number
    }
    area: {
        id: number
    }
    gatewayPicture?: {
        id: number
    },
    gatewayPictures?:{
        id: number,
    }[]
}

export interface IConfirmInstallationResponse {
    latitude: number
    longitude: number
    location: {
        id: number
    }
    area: {
        id: number
    }
    gatewayPicture?: {
        id: number
    }
    gatewayPictures?:{
        id: number,
    }[]
}
const useConfirmInstallation = (onSuccess:()=> void): UseMutationResult<
    IConfirmInstallationResponse,
    unknown,
    { id: number; body: IConfirmInstallationRequest }
> => {
    const config = useGetAxiosConfig()

    return useMutation({
        mutationKey: ['useConfirmInstallation'],
        onSuccess,
        mutationFn: (props) => {
            return axios.patch(API_URLS.getConfirmInstallationUrl(props.id), props.body, config)
        },
    })
}

export default useConfirmInstallation
