import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import APP_URLS from '@src/constants/AppUrls'
import gatewayInstalledIcon from '@src/assets/install_gateway_Illustration.svg'
import InstallGatewayLayout from '@src/shared-components/InstallGatewayLayout'

import classes from './InstallGateway.style'

function InstallGateway(): JSX.Element {
    const {
        t,
    } = useT()
    const navigate = useNavigate()

    const handleNextClick = useCallback(() => {
        navigate(APP_URLS.takePhotoOverviewEmpty)
    }, [navigate])

    const onBackClick = useCallback(() => {
        navigate(APP_URLS.gatewayDetected)
    }, [navigate])

    return (
        <InstallGatewayLayout
            onBackClick={onBackClick}
            onNextClick={handleNextClick}
            nextButtonText={t('CONFIRM')}
        >
            <center css={classes.header}>
                <Typography variant="headline5">
                    {t('CHECK_GATEWAY_INSTALLED')}
                </Typography>
            </center>
            <div
                css={classes.previewImg}
                style={{
                    backgroundImage: `url(${gatewayInstalledIcon}`,
                }}
            />
        </InstallGatewayLayout>
    )
}

export default InstallGateway
