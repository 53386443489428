import {
    createContext,
} from 'react'
import noop from 'lodash/noop'

import {
    IAuthContext,
} from './Auth.types'
import {
    INITIAL_USER_INFO,
} from './const'

const AuthContext = createContext<IAuthContext>({
    userInfo: INITIAL_USER_INFO,
    token: '',
    logout: noop,
})

export default AuthContext
