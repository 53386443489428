import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        color: white;
        text-transform: uppercase;
        caret-color: transparent;
        text-decoration: none;
    `,
}

export default classes
