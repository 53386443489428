import {
    useMemo,
} from 'react'
import {
    ILocationModel,
} from './api/hooks/useGetGatewayById'
import {
    ISelectOption,
} from './shared-components/Select/Select'
import {
    ILocationAreaModel, ILocationAreaResponse,
} from './api/hooks/useGetLocationArea'

const formatLocationOptions = (item?: ILocationModel): ISelectOption<ILocationModel>[] => {
    if (!item) return []
    return [{
        label: `${item.iataCode} ${item.locationName}`,
        value: item.id.toString(),
        data: item,
    }]
}

const formatLocationsOptions = (items?: ILocationModel[]):
    ISelectOption<ILocationModel>[] => {
    return (
        items?.map((item: ILocationModel): ISelectOption<ILocationModel> => {
            return {
                label: `${item.iataCode ? `${item.iataCode} ` : ''}${item.locationName}`,
                value: item.id.toString(),
                data: item,
            }
        }) || []
    )
}

const useGetLocationAreaOptions = (data?: ILocationAreaResponse):
    ISelectOption<ILocationAreaModel>[] => {
    return useMemo(() => {
        return (
            data?.map((item: ILocationAreaModel): ISelectOption<ILocationAreaModel> => {
                return {
                    label: item.areaName,
                    value: item.id.toString(),
                    data: item,
                }
            }) || []
        )
    }, [data])
}

const dictionary = {
    useGetLocationAreaOptions,
    formatLocationOptions,
    formatLocationsOptions,
}

export default dictionary
