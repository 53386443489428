import {
    useCallback,
    useState,
} from 'react'

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
}

const useGetGeoLocation = (onSuccess?:(crd: GeolocationCoordinates)=>void) => {
    const [
        isSupported,
        seIsSupported,
    ] = useState<boolean>(!!navigator.geolocation)
    const onError = useCallback((error: GeolocationPositionError) => {
        // eslint-disable-next-line no-console
        console.log(`ERROR(${error.code}): ${error.message}`)
        seIsSupported(false)
    }, [])
    const onAfterSuccess = useCallback((position: GeolocationPosition) => {
        const crd = position.coords

        if (onSuccess) {
            onSuccess(crd)
        }
    }, [onSuccess])

    const getCurrentCoordinates = useCallback(() => {
        navigator.geolocation.getCurrentPosition(onAfterSuccess, onError, options)
    }, [
        onAfterSuccess,
        onError,
    ])

    return {
        getCurrentCoordinates,
        isSupported,
    }
}

export default useGetGeoLocation
