import {
    css,
} from '@emotion/react'
import THEME from '@src/constants/Theme'
import {
    TVariant,
} from './Button'

const classes = {
    root: ({
        variant, disabled,
    }: { variant?: TVariant; disabled?: boolean }) => {
        return [
            css`
                cursor: pointer;
                border-radius: 8px;
                display: flex;
                width: 100%;
                height: 48px;
                padding: 6px 6px 6px 8px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;

                div {
                    display: flex;
                    padding: 4px 8px 4px 6px;
                    align-items: flex-start;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 114.286% */
                    letter-spacing: 1.25px;
                    text-transform: uppercase;
                }
            `,
            variant === 'primary'
                && css`
                    color: white;
                    background-color: ${THEME.skycell_primary_color};
                    border: 1px solid ${THEME.skycell_primary_color};
                `,

            variant === 'primary-outlined'
                && css`
                    background-color: #ffffff;
                    border: 1px solid #00739a;
                    color: #00739a;
                `,

            variant === 'secondary'
                && css`
                    background-color: #ffbfbf;
                    border: 1px solid #ffffff;
                    color: white;
                `,

            variant === 'secondary-outlined'
                && css`
                    background-color: #ffffff;
                    border: 1px solid #d44848;
                    color: #d44848;
                `,

            variant === 'tertiary'
                && css`
                    background-color: ${THEME.skycell_dark};
                    border: 1px solid ${THEME.skycell_dark};
                    color: white;
                `,

            variant === 'quaternary'
                && css`
                    background-color: white;
                    border: 1px solid white;
                    color: black;
                `,
            variant === 'quinary'
                && css`
                    color: white;
                    background-color: ${THEME.skycell_primary_color};
                    border: 1px solid white;
                `,

            disabled
                && css`
                    border: 1px solid #eee;
                    opacity: 0.6;
                    cursor: default;
                `,

            disabled
            && variant !== 'primary'
            && css`
                    background-color: #eee;
                    color: gray;
                `,
        ]
    },
}

export default classes
