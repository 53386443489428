import React, {
    ReactNode,
    useState,
    useMemo,
    useCallback, useEffect, HTMLAttributes,
} from 'react'
import debounce from 'lodash/debounce'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@src/shared-components/Typography'

import classes from './AutoComplete.style'

const DEBOUNCE_TIME = 500

export interface IAutoCompleteOption<TValue = object> {
    label: ReactNode
    value: string
    data?: TValue
}
export type AutoCompleteProps = {
    name: string
    options: IAutoCompleteOption[]
    value?: string
    onChange?: (name: string, value: string) => void
    onBlur?: (name: string) => void
    label?: ReactNode
    helperText?: ReactNode
    applySearchValue?: (value: string) => void,
    renderOption?: (
        props: HTMLAttributes<HTMLLIElement>,
        option: IAutoCompleteOption
    ) => ReactNode,
    getOptionLabel?: (option: IAutoCompleteOption) => string,
}

const findOptionByValue = (options: IAutoCompleteOption[], value: string | undefined) => {
    return options.find((item: IAutoCompleteOption) => {
        return item.value.toString() === value?.toString()
    })
}

function AutoComplete({
    name,
    label,
    helperText,
    onBlur,
    onChange,
    options,
    applySearchValue,
    ...rest
}: Readonly<AutoCompleteProps>): JSX.Element {
    const [
        value,
        setValue,
    ] = useState<IAutoCompleteOption | null>(() => {
        return findOptionByValue(options, rest.value) || null
    })

    const [
        inputValue,
        setInputValue,
    ] = React.useState('')

    const [
        open,
        setOpen,
    ] = useState(false)

    const sendApplySearchValue = useCallback((newSearchValue: string) => {
        if (applySearchValue) {
            applySearchValue(newSearchValue)
        }
    }, [applySearchValue])

    const memorizedDebounceApplySearchValue = useMemo(() => {
        return debounce(sendApplySearchValue, DEBOUNCE_TIME)
    }, [sendApplySearchValue])

    const handleSearch = useCallback((event: React.SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue)
        if (event && event.type === 'change') {
            memorizedDebounceApplySearchValue(newInputValue)
        }
    }, [memorizedDebounceApplySearchValue])

    const handleChange = useCallback(
        (_e: React.SyntheticEvent, item: IAutoCompleteOption | null) => {
            setValue(item)
            if (onChange) {
                onChange(name, item?.value ?? '')
            }
        },
        [
            onChange,
            name,
        ],
    )

    const handleBlur = useCallback(() => {
        if (onBlur) {
            onBlur(name)
        }
    }, [
        onBlur,
        name,
    ])

    const onClose = useCallback(() => {
        setOpen(false)
        if (applySearchValue) {
            applySearchValue('')
        }
    }, [
        setOpen,
        applySearchValue,
    ])

    /*  Need to set current state value by Form Context value */
    useEffect(() => {
        const defaultValue = findOptionByValue(options, rest.value)

        if (defaultValue && defaultValue !== value) {
            setValue(defaultValue)

            if (onChange) {
                onChange(name, defaultValue.value.toString() || '')
            }
        }
    }, [
        name,
        onChange,
        options,
        rest.value,
        value,
    ])

    const getOptionLabel = useMemo(() => {
        if (rest?.getOptionLabel) { return rest?.getOptionLabel }
        return (option: IAutoCompleteOption) => { return option.label as string }
    }, [rest?.getOptionLabel])

    return (
        <div>
            {label && (
                <Typography
                    variant="label"
                    css={classes.label}
                >
                    {label}
                </Typography>
            )}
            <Autocomplete
                id={name}
                open={open}
                onOpen={() => {
                    setOpen(true)
                }}
                size="small"
                sx={{
                    '& input': {
                        pl: 0,
                    },
                    '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
                        pl: '1px',
                    },
                    '& fieldset': {
                        borderColor: '#000',
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                        color: '#000',
                    },
                }}
                onClose={onClose}
                filterOptions={(x) => { return x }}
                isOptionEqualToValue={(option, item) => { return option.value === item.value }}
                getOptionLabel={getOptionLabel}
                options={options}
                renderOption={rest?.renderOption}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                inputValue={inputValue}
                onInputChange={handleSearch}
                selectOnFocus
                clearOnBlur
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <div>
                                        {params.InputProps.endAdornment}
                                    </div>
                                ),
                            }}
                        />
                    )
                }}
            />
            {helperText}
        </div>
    )
}

export default AutoComplete
