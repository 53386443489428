import React, {
    ReactNode,
    useCallback,
} from 'react'
import consoleService from '@src/services/consoleService'
import openSupport from '@src/utils/jiraHelpDesk'
import ErrorWithSupport from './components/ErrorWithSupport'

type Props = {
    message?: string | ReactNode
    onBackClick: () => void
    header?: string | ReactNode
}

function ErrorWithSupportPage({
    message, onBackClick, header,
}: Readonly <Props>): JSX.Element {
    const jiraHelpDeskCallback = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleSupportClick = useCallback(() => {
        jiraHelpDeskCallback()
    }, [jiraHelpDeskCallback])

    return (
        <ErrorWithSupport
            onBackClick={onBackClick}
            onSupportClick={handleSupportClick}
            message={message}
            header={header}
        />
    )
}

export default ErrorWithSupportPage
