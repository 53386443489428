import React, {
    useEffect, useMemo, useState,
} from 'react'
import RectanglePng from '@src/assets/rectangle.png'
import {
    Constraints, WebCamScanResult,
} from '@src/utils/dataTypes'
import {
    Result,
} from '@zxing/library'
import classes, {
    BARCODE_MARGIN,
} from './QrCodeScanner.style'
import BarcodeScannerComponent from '../BarcodeScannerComponent'

type Props = {
    onScan: (value: string) => void
}

const CAMERA_WIDTH = 341 - BARCODE_MARGIN * 2
const CAMERA_HEIGHT = 335 - BARCODE_MARGIN * 2

function QrCodeScanner({
    onScan,
}: Props) {
    const [
        visible,
        setVisible,
    ] = useState(false)

    const videoConstraints = useMemo(() => {
        // change resolution to 720x720 (on IOS not resized fully)
        const constraints: Constraints = {
            height: 720,
            width: 720,
            aspectRatio: 1,
            facingMode: 'environment',
        }

        return constraints
    }, [])

    useEffect(() => {
        setVisible(true)
    }, [])

    const handleCodeChange = (err: unknown, result: Result | undefined) => {
        if (err) {
            return
        }

        if (result) {
            const {
                text,
            } = result as unknown as WebCamScanResult

            if (!text || text?.length === 0) {
                return
            }

            onScan(text)
        }
    }

    return visible ? (
        <div css={classes.root}>
            <div
                css={classes.rectangleImg}
                style={{
                    backgroundImage: `url(${RectanglePng})`,
                }}
            >
                <BarcodeScannerComponent
                    css={classes.video}
                    videoConstraints={videoConstraints}
                    width={CAMERA_WIDTH}
                    height={CAMERA_HEIGHT}
                    onUpdate={handleCodeChange}
                />
            </div>
        </div>
    ) : null
}

export default QrCodeScanner
