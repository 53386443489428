import React, {
    useCallback, useMemo,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import useSetup from '@src/hooks/useSetup/useSetup'
import APP_URLS from '@src/constants/AppUrls'
import queryClient from '@src/services/queryClient'
import Summary from './components/Summary'

function SummaryPage(): JSX.Element {
    const [
        state,
        setState,
    ] = useSetup()
    const navigate = useNavigate()

    const handleSubmit = useCallback(() => {
        setState(() => {
            return {}
        })
        queryClient.resetQueries()
        navigate(APP_URLS.scanQR)
    }, [
        navigate,
        setState,
    ])

    const locationName = useMemo(() => {
        if (state.selectedLocation?.iataCode) {
            return `${state.selectedLocation.iataCode} ${state.selectedLocation.city}`
        }
        return state.selectedLocation?.locationName || ''
    }, [state.selectedLocation])

    return (
        <Summary
            gateway={`${state.gatewayMac}`}
            location={locationName}
            area={`${state.selectedArea?.areaName}`}
            onSubmit={handleSubmit}
        />
    )
}

export default SummaryPage
