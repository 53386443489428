import {
    css,
} from '@emotion/react'

const classes = {
    label: css`
        padding-bottom: 7px;
    `,
}

export default classes
