import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        overflow: hidden;
        flex: 1;
    `,
    header: css`
        padding: 32px 16px;
        text-align: center;
    `,
    subHeader: css`
        padding-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    `,

    previewImg: css`
        display: flex;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
        flex-grow: 1;
    `,
}

export default classes
