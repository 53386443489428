import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0px 22px;
    `,
    icon: css`
        padding-top: 124px;
        padding-bottom: 16px;
        width: 88px;
        height: 88px;
    `,
    title: css`
        font-size: 34px;
        line-height: 36px; /* 105.882% */
    `,
    text: css`
        font-size: 16px;
        line-height: 24px; /* 150% */
        letter-spacing: 0.5px;
        padding-top: 24px;
    `,
}

export default classes
