// used same breakpoints https://mui.com/material-ui/customization/breakpoints/
export const SCREENS = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
}

export const MQ = {
    xs: `@media (min-width: ${SCREENS.xs}px)`,
    sm: `@media (min-width: ${SCREENS.sm}px)`,
    md: `@media (min-width: ${SCREENS.md}px)`,
    lg: `@media (min-width: ${SCREENS.lg}px)`,
    xl: `@media (min-width: ${SCREENS.xl}px)`,
}
