import {
    useCallback, useEffect, useRef,
} from 'react'

type Return = {
    timeout: (callback: () => void, interval?: number) => void
}

const useTimeout = (): Return => {
    const clearIntervalRef = useRef<NodeJS.Timeout>()

    useEffect(() => {
        return () => {
            return clearIntervalRef.current && clearTimeout(clearIntervalRef.current)
        }
    }, [])
    return {
        timeout: useCallback((callback, interval) => {
            if (clearIntervalRef.current) {
                clearTimeout(clearIntervalRef.current)
            }
            clearIntervalRef.current = setTimeout(() => {
                callback()
            }, interval || 0)
        }, []),
    }
}

export default useTimeout
