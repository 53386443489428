import React from 'react'
import {
    useLocation,
    Navigate,
} from 'react-router-dom'

function RedirectPage(): JSX.Element {
    const {
        state,
    } = useLocation()

    if (state.origin) {
        return (<Navigate to={state.origin} />)
    }
    return (<Navigate to="/" />)
}

export default RedirectPage
