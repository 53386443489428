import React, {
    ReactNode,
    useState,
} from 'react'
import Button from '@src/shared-components/Button'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup'
import ErrorSvg from '@src/assets/triangle_error.svg'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import classes from './ScanQRError.style'

type Props = {
    onBackClick: () => void
    onSupportClick: () => void
    message?: string | ReactNode,
    header?: string | ReactNode,
}

function ErrorWithSupport({
    onBackClick,
    onSupportClick,
    message,
    header,
}: Readonly <Props>): JSX.Element {
    const {
        t,
    } = useT()
    const [
        mainPopupIsOpen,
        setMainPopupIsOpen,
    ] = useState(false)

    return (
        <Layout
            header={(
                <Header
                    title={t('ERROR')}
                    onMenuClick={() => {
                        return setMainPopupIsOpen((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <Button
                        variant="primary"
                        onClick={onBackClick}
                    >
                        {t('BACK')}
                    </Button>
                    <Button
                        variant="primary-outlined"
                        onClick={onSupportClick}
                    >
                        {t('SUPPORT')}
                    </Button>
                </>
            )}
        >
            <MainPopup
                isOpen={mainPopupIsOpen}
                onClose={() => {
                    return setMainPopupIsOpen(false)
                }}
            />

            <div css={classes.root}>
                <center>
                    <img
                        alt="Error"
                        src={ErrorSvg}
                        css={classes.logo}
                    />
                </center>
                <Typography
                    css={classes.header}
                    variant="headline5"
                >
                    {header || t('SOMETHING_WENT_WRONG')}
                </Typography>
                <Typography
                    css={classes.subHeader}
                    variant="subtitle1"
                >
                    {message}
                </Typography>
            </div>
        </Layout>
    )
}

export default ErrorWithSupport
