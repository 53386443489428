import React, {
    useMemo,
} from 'react'
import parseErrorMessage, {
    IError,
} from '@src/utils/parseErrorMessage'
import Error from './components/Error'

type Props = {
    error?: unknown
    onBackClick?: () => void
}
function ErrorPage({
    error, onBackClick,
}: Props): JSX.Element {
    const message = useMemo(() => {
        return parseErrorMessage(error as IError)
    }, [error])

    return (
        <Error
            onBackClick={onBackClick}
            message={message}
        />
    )
}

export default ErrorPage
