import React from 'react'

import Select, {
    SelectProps,
} from './Select'
import useFormSelectOnChangeBlurHandling from './useFormSelectChangeBlurHandling'

interface IProps<TFormValues> extends Omit<SelectProps, 'name'> {
    name: keyof TFormValues
}

function FormSelect<TFormValues>({
    name, onChange, onBlur, ...rest
}: IProps<TFormValues>): JSX.Element {
    const {
        handleChange,
        handleBlur,
        field,
    } = useFormSelectOnChangeBlurHandling({
        name: name as string,
        onChange,
        onBlur,
    })

    return (
        <Select
            {...rest}
            value={field.value}
            name={name as string}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    )
}

export default FormSelect
