import React, {
    useState,
    useCallback,
} from 'react'
import {
    Autocomplete,
} from '@react-google-maps/api'
import {
    useTranslation,
} from 'react-i18next'

import classes from './MapSearch.style'

function MapSearch({
    setSelectedPlace,
}:{
    setSelectedPlace: (location: google.maps.LatLng) => void,
}): JSX.Element {
    const {
        t,
    } = useTranslation()
    const [
        searchResult,
        setSearchResult,
    ] = useState<google.maps.places.Autocomplete>()

    const onLoadSearch = useCallback((autocomplete: google.maps.places.Autocomplete) => {
        setSearchResult(autocomplete)
    }, [])

    const locationSelected = useCallback(() => {
        if (searchResult) {
            const place = searchResult.getPlace()

            if (place.geometry?.location) {
                setSelectedPlace(place.geometry?.location)
            }
        }
    }, [
        searchResult,
        setSelectedPlace,
    ])

    return (
        <Autocomplete
            onLoad={onLoadSearch}
            onPlaceChanged={locationSelected}
        >
            <input
                type="text"
                key="search-box"
                placeholder={t('SEACRH_PLACEHOLDER')}
                css={classes.search}
            />
        </Autocomplete>
    )
}

export default MapSearch
