import React, {
    useState,
} from 'react'
import Button from '@src/shared-components/Button'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup'
import LockSvg from '@src/assets/lock.svg'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import classes from './AccessDenied.style'

type Props = {
    onBackClick: () => void
}

function AccessDenied({
    onBackClick,
}: Props): JSX.Element {
    const {
        t,
    } = useT()
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)

    return (
        <Layout
            header={(
                <Header
                    title={t('ONBOARDING')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <Button
                    variant="primary"
                    onClick={onBackClick}
                >
                    {t('BACK')}
                </Button>
            )}
        >
            <MainPopup
                isOpen={isPopupOpened}
                onClose={() => {
                    return showMainPopup(false)
                }}
            />

            <div css={classes.root}>
                <center>
                    <img
                        alt="Logo"
                        src={LockSvg}
                        css={classes.logo}
                    />
                </center>
                <Typography
                    css={classes.header}
                    variant="headline5"
                >
                    {t('ACCESS_DENIED_HEADER')}
                </Typography>
                <Typography
                    css={classes.subHeader}
                    variant="body2"
                >
                    {t('ACCESS_DENIED_SUB_HEADER')}
                </Typography>
            </div>
        </Layout>
    )
}

export default AccessDenied
