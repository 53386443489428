import React, {
    ReactNode,
} from 'react'
import classes from './Button.style'

export type TVariant =
    | 'primary'
    | 'primary-outlined'
    | 'secondary'
    | 'secondary-outlined'
    | 'tertiary'
    | 'quaternary'
    | 'quinary'
type Props = {
    disabled?: boolean
    variant?: TVariant
    children: ReactNode
    onClick?: () => void
}

function Button({
    disabled, children, onClick, variant = 'primary',
}: Props): JSX.Element {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            css={classes.root({
                variant,
                disabled,
            })}
        >
            <div>{children}</div>
        </button>
    )
}

export default Button
