import {
    css,
} from '@emotion/react'
import THEME from '@src/constants/Theme'
import {
    TVariant,
} from './Input'

const classes = {
    inputContainer: (variant: TVariant) => {
        return [
            css`
                position: relative;
                display: flex;
                outline: none;

                input {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 15px;
                    display: block;
                    outline: none;
                    caret-color: transparent;
                    width: 100%;
                    border-radius: 6px;
                }

                input:focus {
                    outline: none;
                    caret-color: inherit;
                }

                label {
                    color: ${THEME.gray};
                    font-size: 18px;
                    font-weight: normal;
                    position: absolute;
                    pointer-events: none;
                    left: 16px;
                    top: 16px;
                    transition: 0.2s ease all;
                    -moz-transition: 0.2s ease all;
                    -webkit-transition: 0.2s ease all;
                }
            `,
            variant === 'primary'
                && css`
                    input {
                        border: 1px solid ${THEME.gray};
                    }

                    input:focus ~ label,
                    input:-webkit-autofill ~ label,
                    input:not([value='']) ~ label {
                        top: -8px;
                        left: 22px;
                        background: white;
                        padding: 0px 4px;
                        font-size: 14px;
                    }
                `,
            variant === 'secondary'
                && css`
                    input {
                        border: 1px solid #fff;
                        background-color: transparent;
                        color: white;
                    }

                    label {
                        color: white;
                        background-color: transparent;
                        opacity: 0.3;
                    }

                    input:-webkit-autofill {
                        -webkit-text-fill-color: #ffffff;
                        background-color: ${THEME.skycell_primary_color}!important;
                        -webkit-background-clip: text;
                    }

                    input:focus ~ label,
                    input:-webkit-autofill ~ label,
                    input:not([value='']) ~ label {
                        top: -8px;
                        left: 22px;
                        padding: 0px 4px;
                        font-size: 14px;
                        background-color: ${THEME.skycell_primary_color};
                        opacity: 1;
                    }
                `,
        ]
    },

    helperText: (variant: TVariant, noHelper?: boolean) => {
        return [
            css`
                height: 16px;
                padding-left: 16px;
                color: ${THEME.gray};
            `,
            variant === 'secondary'
                && css`
                    color: white;
                    opacity: 0.3;
                `,
            noHelper && css`
                display: none;
            `,
        ]
    },

    label: (variant: TVariant) => {
        return [
            css`
                padding-bottom: 7px;
            `,
            variant === 'secondary'
                && css`
                    color: white;
                    opacity: 0.3;
                `,
        ]
    },
}

export default classes
