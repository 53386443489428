import {
    useCallback, useState,
} from 'react'
import useTimeout
    from '../useTimeout/useTimeout'

type Return<TState> = [TState, (callback: (prev: TState) => TState, complete?: () => void) => void]
function useSessionStorageState<TState>(name: string, initial?: TState): Return<TState> {
    const {
        timeout,
    } = useTimeout()
    const [
        state,
        setState,
    ] = useState<TState>(
        sessionStorage.getItem(name)
            ? (JSON.parse(sessionStorage.getItem(name) as string) as unknown as TState)
            : (initial as TState),
    )

    return [
        state || ({} as TState),
        useCallback(
            (callback: (prev: TState) => TState, complete?: () => void): void => {
                setState((prev): TState => {
                    const res = callback(prev)

                    sessionStorage.setItem(name, JSON.stringify(res))
                    return res
                })

                if (complete) {
                    timeout(complete, 1)
                }
            },
            [
                name,
                timeout,
            ],
        ),
    ]
}

export default useSessionStorageState
