import {
    useRef,
} from 'react'

const deepCompareEquals = (a: unknown, b: unknown): boolean => {
    return JSON.stringify(a) === JSON.stringify(b)
}

function useDeepCompareMemorized<T>(value: T): T {
    const ref = useRef<T>(value)

    if (!deepCompareEquals(value, ref.current)) {
        ref.current = value
    }

    return ref.current
}

export default useDeepCompareMemorized
