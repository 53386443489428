import React, {
    useMemo,
    useState,
    useCallback,
    useEffect,
} from 'react'
import {
    GoogleMap,
    MarkerF,
} from '@react-google-maps/api'

import goAutoMode from '@src/assets/re-center-white.svg'
import useGetGeoLocation from '@src/shared-components/MapApiProvider/hooks/useGetGeoLocation'

import {
    ZOOM_FOR_10_METERS,
    ZURICH_LAT_LNG,
} from '../constants'
import {
    calculateMapContainerStyles,
    convertLatLngToLiteral,
    convertCoordsToLiteral,
} from '../utils'
import MapSearch from '../MapSearch'

import classes from '../Map.style'

type Props = {
    footerHeight: number,
    onAutoModeClick?: ()=> void,
    setSelectedCoordinates: (coords: google.maps.LatLngLiteral)=> void,
    draggable: boolean,
    switchToReadyMode: () => void,
}

function ManualMap({
    footerHeight,
    onAutoModeClick,
    setSelectedCoordinates,
    draggable,
    switchToReadyMode,
}:Props): JSX.Element {
    const [
        map,
        setMap,
    ] = useState<google.maps.Map>()

    const [
        geolocationFromMap,
        setGeolocationFromMap,
    ] = useState<google.maps.LatLng>()

    const [
        currentCenter,
        setCurrentCenter,
    ] = useState<google.maps.LatLngLiteral>(ZURICH_LAT_LNG)

    const containerStyle = useMemo(() => {
        return calculateMapContainerStyles(footerHeight)
    }, [footerHeight])

    const onLoad = useCallback((currentMap: google.maps.Map) => {
        const bounds = new window.google.maps.LatLngBounds(ZURICH_LAT_LNG)

        currentMap.fitBounds(bounds)
        setMap(currentMap)
    }, [])

    const onUnmount = useCallback(() => {
        setMap(undefined)
    }, [])

    const onCenterChanged = useCallback(() => {
        const newMapCenter = map?.getCenter()

        if (newMapCenter) {
            setGeolocationFromMap(newMapCenter)
            setSelectedCoordinates(convertLatLngToLiteral(newMapCenter))
        }
    }, [
        map,
        setSelectedCoordinates,
    ])

    const applyRealCoordinates = useCallback((coords: GeolocationCoordinates) => {
        const newCenter = convertCoordsToLiteral(coords)

        setCurrentCenter(newCenter)
        map?.setCenter(newCenter)
        setSelectedCoordinates(newCenter)
    }, [
        map,
        setSelectedCoordinates,
    ])

    const {
        getCurrentCoordinates,
        isSupported,
    } = useGetGeoLocation(applyRealCoordinates)

    useEffect(() => {
        if (isSupported) {
            getCurrentCoordinates()
        }
    }, [
        getCurrentCoordinates,
        isSupported,
    ])

    const mapOptions = useMemo(() => {
        return {
            disableDefaultUI: true,
            scaleControl: true,
            mapTypeId: 'hybrid',
            draggable,
        }
    }, [draggable])

    const setSelectedPlace = useCallback((location: google.maps.LatLng) => {
        setGeolocationFromMap(location)
        map?.setCenter(location)
        setSelectedCoordinates(convertLatLngToLiteral(location))
        if (!draggable) {
            switchToReadyMode()
        }
    }, [
        map,
        setSelectedCoordinates,
        switchToReadyMode,
        draggable,
    ])

    return (
        <div
            data-key="root"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={currentCenter}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onCenterChanged={onCenterChanged}
                zoom={ZOOM_FOR_10_METERS}
                options={mapOptions}
            >
                <MapSearch setSelectedPlace={setSelectedPlace} />
                { onAutoModeClick && (
                    <div
                        role="button"
                        tabIndex={0}
                        css={classes.icon}
                        onClick={onAutoModeClick}
                        aria-hidden="true"
                    >
                        <img
                            src={goAutoMode}
                            alt="Center"
                        />
                    </div>
                )}
                { geolocationFromMap && (
                    <MarkerF
                        icon="https://assets.dev.skycell.ch/skygate/map-marker-manual-position.svg"
                        position={geolocationFromMap}
                    />
                )}
            </GoogleMap>
        </div>
    )
}

export default ManualMap
