import React from 'react'

import useFormSelectOnChangeBlurHandling from '@src/shared-components/Select/useFormSelectChangeBlurHandling'

import AutoComplete, {
    AutoCompleteProps,
} from './AutoComplete'

interface IProps<TFormValues> extends Omit<AutoCompleteProps, 'name'> {
    name: keyof TFormValues
}

function FormAutoComplete<TFormValues>({
    name, onChange, onBlur, ...rest
}: Readonly<IProps<TFormValues>>): JSX.Element {
    const {
        handleChange,
        handleBlur,
        field,
    } = useFormSelectOnChangeBlurHandling({
        name: name as string,
        onChange,
        onBlur,
    })

    return (
        <AutoComplete
            {...rest}
            value={field.value}
            name={name as string}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    )
}

export default FormAutoComplete
