import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        flex-grow: 1;
        width: 100%;
        display: flex;
        min-height: 100%;
        height:100%;
        align-items: center;
        flex-direction: column;
    `,
    footerCnt: css`
        margin-top: auto;
        width: 100%;
    `,
    footer: css`
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        column-gap: 16px;
        align-items: center;
        margin: 24px;
    `,
    header: css`
        padding: 0px 16px;
        text-align: center;
    `,
    subHeader: css`
        padding: 0px 16px;
        text-align: center;
    `,
}

export default classes
