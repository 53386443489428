import unionBy from 'lodash/unionBy'
import {
    PhotoType,
} from '@src/context/PhotosContext/PhotosContext'

const mergePhotos = (newData: PhotoType[], prev: PhotoType[]): PhotoType[] => {
    return unionBy(newData, prev, 'base64')
}

export default mergePhotos
