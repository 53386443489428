import React, {
    useMemo,
    useState,
    useCallback,
    useEffect,
} from 'react'
import {
    GoogleMap,
    MarkerF,
} from '@react-google-maps/api'

import ReCenterIcon from '@src/assets/re-center-blue.svg'
import useGetGeoLocation from '@src/shared-components/MapApiProvider/hooks/useGetGeoLocation'

import {
    ZOOM_FOR_10_METERS,
    ZURICH_LAT_LNG,
} from '../constants'
import {
    calculateMapContainerStyles,
    convertCoordsToLiteral,
} from '../utils'

import classes from '../Map.style'

function AutoMap({
    footerHeight,
    setSelectedCoordinates,
}:{
    footerHeight: number,
    setSelectedCoordinates: (coords: google.maps.LatLngLiteral)=> void,
}): JSX.Element {
    const [
        map,
        setMap,
    ] = useState<google.maps.Map>()

    const [
        currentCenter,
        setCurrentCenter,
    ] = useState<google.maps.LatLngLiteral>(ZURICH_LAT_LNG)

    const containerStyle = useMemo(() => {
        return calculateMapContainerStyles(footerHeight)
    }, [footerHeight])

    const mapCenter = useMemo(() => {
        return currentCenter
    }, [currentCenter])

    const onLoad = useCallback((currentMap: google.maps.Map) => {
        const bounds = new window.google.maps.LatLngBounds(mapCenter)

        currentMap.fitBounds(bounds)
        setMap(currentMap)
    }, [mapCenter])

    const onUnmount = useCallback(() => {
        setMap(undefined)
    }, [])

    const applyNewCoordinates = useCallback((coords: GeolocationCoordinates) => {
        const newCenter = convertCoordsToLiteral(coords)

        setCurrentCenter(newCenter)
        map?.setCenter(newCenter)
        setSelectedCoordinates(newCenter)
    }, [
        map,
        setSelectedCoordinates,
    ])

    const {
        getCurrentCoordinates,
    } = useGetGeoLocation(applyNewCoordinates)

    const reCenterHandler = useCallback(() => {
        getCurrentCoordinates()
    }, [getCurrentCoordinates])

    useEffect(() => {
        getCurrentCoordinates()
    }, [getCurrentCoordinates])

    return (
        <div
            data-key="root"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapCenter}
                onLoad={onLoad}
                onUnmount={onUnmount}
                zoom={ZOOM_FOR_10_METERS}
                options={{
                    disableDefaultUI: true,
                    scaleControl: true,
                    mapTypeId: 'hybrid',
                }}
            >
                <div
                    role="button"
                    tabIndex={0}
                    css={classes.icon}
                    onClick={reCenterHandler}
                    aria-hidden="true"
                >
                    <img
                        src={ReCenterIcon}
                        alt="Center"
                    />
                </div>
                <MarkerF
                    icon="https://assets.dev.skycell.ch/skygate/map-marker-current-position.svg"
                    position={mapCenter}
                />
            </GoogleMap>
        </div>
    )
}

export default AutoMap
