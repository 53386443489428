import React, {
    HTMLAttributes,
    useCallback,
} from 'react'
import {
    IAutoCompleteOption,
} from '@src/shared-components/AutoComplete/AutoComplete'
import {
    ILocationModel,
} from '@src/api/hooks/useGetGatewayById'
import Box from '@mui/material/Box'
import FormAutoComplete from '@src/shared-components/AutoComplete'
import {
    FormProps,
} from '@src/pages/GatewayDetected/components/GatewayDetected'
import useT from '@src/hooks/useT'
import classes from './LocationSelector.style'

const LocationSelector = ({
    helperText,
    handleLocationChange,
    locationOptions,
    applySearchValue,
    getOptionLabel,
}: {
    handleLocationChange: (_name: string, value: string) => void
    locationOptions: IAutoCompleteOption<ILocationModel>[],
    applySearchValue: (val: string) => void,
    helperText?: React.ReactNode | string,
    getOptionLabel?: (option: IAutoCompleteOption<object>) => string
}) => {
    const {
        t,
    } = useT()

    const renderOption = useCallback((
        props: HTMLAttributes<HTMLLIElement>,
        option: IAutoCompleteOption<ILocationModel>,
    ) => {
        const {
            iataCode,
            locationName,
            addressLine1,
            city,
            countryName,
        } = option.data || {}

        return (
            <Box
                css={classes.option}
                component="li"
                {...props}
            >
                <div>
                    {iataCode ? option.label : locationName}
                </div>
                <div css={classes.detail}>
                    {addressLine1 ? `${addressLine1}, ` : ''}
                    {city ? `${city}, ` : ''}
                    {countryName ? `${countryName}` : ''}
                </div>
            </Box>
        )
    }, [])

    return (
        <FormAutoComplete<FormProps>
            onChange={handleLocationChange}
            options={locationOptions || []}
            renderOption={renderOption as
                (props: HTMLAttributes<HTMLLIElement>,
                 option: IAutoCompleteOption<object>
                ) => React.ReactNode}
            getOptionLabel={getOptionLabel}
            name="location"
            label={t('LOCATION')}
            helperText={helperText}
            applySearchValue={applySearchValue}
        />
    )
}

export default LocationSelector
