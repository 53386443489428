import React, {
    ReactNode,
} from 'react'
import classes from './FormError.style'

type Props = {
    children: ReactNode
    className?: string
}

function FormError({
    children, className,
}: Props): JSX.Element {
    return (
        <div
            css={classes.root}
            className={className}
        >
            {children}
        </div>
    )
}

export default FormError
