const getCameraOrientation = () => {
    const {
        type,
        // eslint-disable-next-line no-restricted-globals
    } = screen.orientation

    return type.startsWith('portrait') ? 'portrait' : 'landscape'
}

export default getCameraOrientation
