import React, {
    useState,
} from 'react'
import MainPopup from '@src/MainPopup'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import Button from '@src/shared-components/Button'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import classes from './Terms.style'

type Props = {
    onAccept: () => void
}

function Terms({
    onAccept,
}: Props): JSX.Element {
    const {
        t,
    } = useT()
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)

    return (
        <Layout
            header={(
                <Header
                    title={t('ONBOARDING')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <Button
                    variant="primary"
                    onClick={onAccept}
                >
                    {t('ACCEPT')}
                </Button>
            )}
        >
            <MainPopup
                isOpen={isPopupOpened}
                onClose={() => {
                    showMainPopup(false)
                }}
            />
            <div css={classes.root}>
                <Typography
                    variant="headline5"
                    css={classes.header}
                >
                    {t('termsConditions:TERMS_AND_CONDITIONS')}
                </Typography>
                <ol css={classes.ol}>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:SCOPE_1')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:SCOPE_1_1')}</li>
                            <li css={classes.li}>{t('termsConditions:SCOPE_1_2')}</li>
                            <li css={classes.li}>{t('termsConditions:SCOPE_1_3')}</li>
                            <li css={classes.li}>{t('termsConditions:SCOPE_1_4')}</li>
                            <li css={classes.li}>{t('termsConditions:SCOPE_1_5')}</li>
                            <li css={classes.li}>{t('termsConditions:SCOPE_1_6')}</li>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:ACCOUNT_2')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:ACCOUNT_2_1')}</li>
                            <li css={classes.li}>{t('termsConditions:ACCOUNT_2_2')}</li>
                            <li css={classes.li}>{t('termsConditions:ACCOUNT_2_3')}</li>
                            <div css={classes.ul}>{t('termsConditions:ACCOUNT_2_3a')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:FEES_3')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:FEES_3_1')}</li>
                            <li css={classes.li}>{t('termsConditions:FEES_3_2')}</li>
                            <li css={classes.li}>{t('termsConditions:FEES_3_3')}</li>
                            <li css={classes.li}>{t('termsConditions:FEES_3_4')}</li>
                            <li css={classes.li}>{t('termsConditions:FEES_3_5')}</li>
                            <li css={classes.li}>{t('termsConditions:FEES_3_6')}</li>
                            <li css={classes.li}>{t('termsConditions:FEES_3_7')}</li>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:DELIVERY_4')}
                        <ol css={classes.ol}>
                            <div css={classes.ul}>{t('termsConditions:DELIVERY_TEXT')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:LICENSE_5')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:LICENSE_5_1')}</li>
                            <li css={classes.li}>{t('termsConditions:LICENSE_5_2')}</li>
                            <li css={classes.li}>{t('termsConditions:LICENSE_5_3')}</li>
                            <li css={classes.li}>{t('termsConditions:LICENSE_5_4')}</li>
                            <li css={classes.li}>{t('termsConditions:LICENSE_5_5')}</li>
                            <li css={classes.li}>{t('termsConditions:LICENSE_5_6')}</li>
                            <li css={classes.li}>{t('termsConditions:LICENSE_5_7')}</li>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:OBLIGATIONS_6')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:OBLIGATIONS_6_1')}</li>
                            <li css={classes.li}>{t('termsConditions:OBLIGATIONS_6_2')}</li>
                            <li css={classes.li}>{t('termsConditions:OBLIGATIONS_6_3')}</li>
                            <li css={classes.li}>{t('termsConditions:OBLIGATIONS_6_4')}</li>
                            <li css={classes.li}>{t('termsConditions:OBLIGATIONS_6_5')}</li>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:USERS_7')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:USERS_7_1')}</li>
                            <li css={classes.li}>{t('termsConditions:USERS_7_2')}</li>
                            <li css={classes.li}>{t('termsConditions:USERS_7_3')}</li>
                            <li css={classes.li}>{t('termsConditions:USERS_7_4')}</li>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:OBLIGATION_SERVICE_8')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:OBLIGATION_SERVICE_8_1')}</li>
                            <li css={classes.li}>{t('termsConditions:OBLIGATION_SERVICE_8_2')}</li>
                            <li css={classes.li}>{t('termsConditions:OBLIGATION_SERVICE_8_3')}</li>
                            <li css={classes.li}>{t('termsConditions:OBLIGATION_SERVICE_8_4')}</li>
                            <li css={classes.li}>{t('termsConditions:OBLIGATION_SERVICE_8_5')}</li>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:THIRD_PARTIES_9')}
                        <ol css={classes.ol}>
                            <div css={classes.ul}>{t('termsConditions:THIRD_PARTIES_9_TEXT_1')}</div>
                            <div css={classes.ul}>{t('termsConditions:THIRD_PARTIES_9_TEXT_2')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:DATA_PROTECTION_10')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:DATA_PROTECTION_10_1')}</li>
                            <div css={classes.ul}>{t('termsConditions:DATA_PROTECTION_10_1_TEXT_1')}</div>
                            <div css={classes.ul}>{t('termsConditions:DATA_PROTECTION_10_1_TEXT_2')}</div>
                            <li css={classes.li}>{t('termsConditions:DATA_PROTECTION_10_2')}</li>
                            <div css={classes.ul}>{t('termsConditions:DATA_PROTECTION_10_2_TEXT_1')}</div>
                            <div css={classes.ul}>{t('termsConditions:DATA_PROTECTION_10_2_TEXT_2')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:RELEASE_11')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:RELEASE_11_1')}</li>
                            <li css={classes.li}>{t('termsConditions:RELEASE_11_2')}</li>
                            <li css={classes.li}>{t('termsConditions:RELEASE_11_3')}</li>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:LIABILITY_12')}
                        <ol css={classes.ol}>
                            <div css={classes.ul}>{t('termsConditions:LIABILITY_TEXT_1')}</div>
                            <div css={classes.ul}>{t('termsConditions:LIABILITY_12_A')}</div>
                            <ul css={classes.dottedItem}>
                                <li>{t('termsConditions:LIABILITY_12_A_1')}</li>
                                <li>{t('termsConditions:LIABILITY_12_A_2')}</li>
                                <li>{t('termsConditions:LIABILITY_12_A_3')}</li>
                                <li>{t('termsConditions:LIABILITY_12_A_4')}</li>
                                <li>{t('termsConditions:LIABILITY_12_A_5')}</li>
                                <li>{t('termsConditions:LIABILITY_12_A_6')}</li>
                                <li>{t('termsConditions:LIABILITY_12_A_7')}</li>
                                <li>{t('termsConditions:LIABILITY_12_A_8')}</li>
                                <li>{t('termsConditions:LIABILITY_12_A_9')}</li>
                            </ul>
                            <div css={classes.ul}>{t('termsConditions:LIABILITY_12_B')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:WARRANTIES_13')}
                        <ol css={classes.ol}>
                            <div css={classes.ul}>{t('termsConditions:WARRANTIES_13_TEXT')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:TERMINATION_14')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:TERMINATION_14_1')}</li>
                            <div css={classes.ul}>{t('termsConditions:TERMINATION_14_1_TEXT')}</div>
                            <li css={classes.li}>{t('termsConditions:TERMINATION_14_2')}</li>
                            <div css={classes.ul}>{t('termsConditions:TERMINATION_14_2_TEXT')}</div>
                            <ul css={classes.dottedItem}>
                                <li>{t('termsConditions:TERMINATION_14_2_1')}</li>
                                <li>{t('termsConditions:TERMINATION_14_2_2')}</li>
                                <li>{t('termsConditions:TERMINATION_14_2_3')}</li>
                            </ul>
                            <li css={classes.li}>{t('termsConditions:TERMINATION_14_3')}</li>
                            <div css={classes.ul}>{t('termsConditions:TERMINATION_14_3_TEXT_1')}</div>
                            <div css={classes.ul}>{t('termsConditions:TERMINATION_14_3_TEXT_2')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:CONFIDENTIALITY_15')}
                        <ol css={classes.ol}>
                            <div css={classes.ul}>{t('termsConditions:CONFIDENTIALITY_15_1')}</div>
                            <div css={classes.ul}>{t('termsConditions:CONFIDENTIALITY_15_2')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:FINAL_PROVISION_16')}
                        <ol css={classes.ol}>
                            <li css={classes.li}>{t('termsConditions:FINAL_PROVISION_16_1')}</li>
                            <div css={classes.ul}>{t('termsConditions:FINAL_PROVISION_16_1_TEXT')}</div>
                            <li css={classes.li}>{t('termsConditions:FINAL_PROVISION_16_2')}</li>
                            <div css={classes.ul}>{t('termsConditions:FINAL_PROVISION_16_2_TEXT_1')}</div>
                            <div css={classes.ul}>{t('termsConditions:FINAL_PROVISION_16_2_TEXT_2')}</div>
                            <li css={classes.li}>{t('termsConditions:FINAL_PROVISION_16_3')}</li>
                            <div css={classes.ul}>{t('termsConditions:FINAL_PROVISION_16_3_TEXT')}</div>
                            <li css={classes.li}>{t('termsConditions:FINAL_PROVISION_16_4')}</li>
                            <div css={classes.ul}>{t('termsConditions:FINAL_PROVISION_16_4_TEXT')}</div>
                            <li css={classes.li}>{t('termsConditions:FINAL_PROVISION_16_5')}</li>
                            <div css={classes.ul}>{t('termsConditions:FINAL_PROVISION_16_5_TEXT')}</div>
                        </ol>
                    </li>
                    <li css={classes.listItemHeader}>
                        {t('termsConditions:JURISDICTION_17')}
                        <ol css={classes.ol}>
                            <div css={classes.ul}>{t('termsConditions:JURISDICTION_17_TEXT_1')}</div>
                            <div css={classes.ul}>{t('termsConditions:JURISDICTION_17_TEXT_2')}</div>
                            <div css={classes.ul}>
                                {t('termsConditions:DOWNLOAD_PDF')}
                                <a
                                    css={classes.link}
                                    href="https://www.skycell.ch/wp-content/uploads/20230201_SkyCell-GTC-for-SECURE-Software_V03.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('termsConditions:HERE_LINK')}
                                </a>
                            </div>
                        </ol>
                    </li>
                </ol>
                <div css={classes.updateDate}>{t('termsConditions:UPDATE_TIME')}</div>
            </div>
        </Layout>
    )
}

export default Terms
