import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'

import LocationContext, {
    LocationContextType,
    CooordinatesType,
} from './LocationContext'

type Props = {
    children: React.ReactNode,
}

const LocationProvider = ({
    children,
}: Props) => {
    const [
        coordinates,
        setCoordinates,
    ] = useState<CooordinatesType>({})

    const setLatLng = useCallback(({
        latitude,
        longitude,
    }: CooordinatesType) => {
        setCoordinates((prev) => {
            return {
                ...prev,
                latitude,
                longitude,
            }
        })
    }, [])

    const contextValue = useMemo<LocationContextType>(() => {
        return {
            setLatLng,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
        }
    }, [
        coordinates.latitude,
        coordinates.longitude,
        setLatLng,
    ])

    return (
        <LocationContext.Provider value={contextValue}>
            {children}
        </LocationContext.Provider>
    )
}

export default LocationProvider
