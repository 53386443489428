import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import APP_URLS from '@src/constants/AppUrls'
import gatewayInstalledIcon from '@src/assets/camera.svg'

import classes from './TakePhotoOverviewEmpty.style'
import TakePhotoOverviewPage from './components/TakePhotoOverviewPage'

function TakePhotoOverviewEmpty(): JSX.Element {
    const navigate = useNavigate()
    const handleBackClick = useCallback(() => {
        navigate(APP_URLS.installGateway)
    }, [navigate])

    const handleNextClick = useCallback(() => {
        navigate(APP_URLS.takePhoto)
    }, [navigate])

    return (
        <TakePhotoOverviewPage
            onBackClick={handleBackClick}
            onTakePhotoClick={handleNextClick}
            allowedTakePhoto
            takePhotoPrimary
        >
            <div
                css={classes.previewImgEmpty}
                style={{
                    backgroundImage: `url(${gatewayInstalledIcon}`,
                }}
            />
        </TakePhotoOverviewPage>
    )
}

export default TakePhotoOverviewEmpty
