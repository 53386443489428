import React, {
    Suspense,
} from 'react'
import {
    createRoot,
} from 'react-dom/client'
import {
    BrowserRouter,
} from 'react-router-dom'
import {
    QueryClientProvider,
} from '@tanstack/react-query'
import {
    ThemeProvider,
} from '@mui/material/styles'

import PhotosProvider from '@src/context/PhotosContext'
import GlobalStyles from './styles/GlobalStyles'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import Auth from './context/AuthContext'
import App from './App'
import './i18n'
import customMuiAutocompleteTheme from './styles/AutoComplete.theme'
import queryClient from './services/queryClient'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
    <Suspense fallback="Loading">
        <GlobalStyles />
        <BrowserRouter basename="/">
            <Auth>
                {/* Keycloack.js have infinite loading when use
                    react 18 + strict mode (components rerenders twice)
                    so moved strict mode after Auth component   */}
                {/* https://github.com/facebook/react/issues/24467 */}
                <React.StrictMode>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={customMuiAutocompleteTheme}>
                            <PhotosProvider>
                                <App />
                            </PhotosProvider>
                        </ThemeProvider>
                    </QueryClientProvider>
                </React.StrictMode>
            </Auth>
        </BrowserRouter>
    </Suspense>,
)
serviceWorkerRegistration.register()
