import {
    UseQueryResult, useQuery,
} from '@tanstack/react-query'
import axios from 'axios'
import {
    UseQueryOptions,
} from '@src/utils/dataTypes'
import API_URLS from '@src/constants/ApiUrls'
import {
    GatewayStatus,
} from '@src/types/enums'

import useGetAxiosConfig from './useGetAxiosConfig'

export interface ILocationModel {
    id: number
    locationName: string
    countryName: string
    iataCode: string
    city: string,
    addressLine1?: string,
}

export interface IGatewayByIdResponse {
    id: number,
    gatewayStatus: GatewayStatus,
    location: {
        id: number
        iataCode: string
        countryName: string
        city: string
        locationName: string
    }
}

const useGetGatewayById = (id: string, options: UseQueryOptions):
UseQueryResult<IGatewayByIdResponse, unknown> => {
    const config = useGetAxiosConfig()

    return useQuery<unknown, unknown, IGatewayByIdResponse>({
        queryFn: () => {
            return axios.get(API_URLS.getGatewayByIdUrl(id), config).then((data) => {
                return data.data
            })
        },
        ...options,
        queryKey: [
            `useGetGatewayById-${id}`,
            id,
        ],
    })
}

export default useGetGatewayById
