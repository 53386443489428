import {
    createContext,
} from 'react'
import noop from 'lodash/noop'

export type PhotoType = {
    base64: string,
    id?: number,
}

export type PhotosContextType = {
    photos: PhotoType[],
    addPhoto: (base64: string) => void,
    removePhoto: (i: number) => void,
    savePhotoIds: (newData :PhotoType[]) => void,
    cleanUp: () => void,
}

const PhotosContext = createContext<PhotosContextType>({
    photos: [],
    addPhoto: noop,
    removePhoto: noop,
    savePhotoIds: noop,
    cleanUp: noop,
})

export default PhotosContext
