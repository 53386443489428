import {
    css,
} from '@emotion/react'

const classes = {
    previewImg: css`
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: auto;
        flex-grow: 1;
        margin: 20px;
        height: 300px;
    `,
    header: css`
        padding: 20px;
        padding-bottom: 0;
    `,
}

export default classes
