import {
    UseMutationResult, useMutation,
} from '@tanstack/react-query'
import axios from 'axios'
import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from './useGetAxiosConfig'

const useAcceptTerms = (): UseMutationResult => {
    const config = useGetAxiosConfig()

    return useMutation({
        mutationKey: ['useAcceptTerms'],
        mutationFn: () => {
            return axios.patch(API_URLS.acceptTermsUrl(), undefined, config)
        },
    })
}

export default useAcceptTerms
