import useAcceptTerms from './useAcceptTerms'
import useAddAttachment from './useAddAttachment'
import useConfirmInstallation from './useConfirmInstallation'
import useGetLocationArea from './useGetLocationArea'

import useHasAcceptedTerms, {
    updateHasAcceptedTermsCache,
} from './useHasAcceptedTerms'

const apiHooks = {
    useAddAttachment,
    useConfirmInstallation,
    useGetLocationArea,

    terms: {
        useHasAcceptedTerms,
        useAcceptTerms,
        updateHasAcceptedTermsCache,
    },
}

export default apiHooks
