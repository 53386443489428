import {
    createContext,
} from 'react'
import noop from 'lodash/noop'

export type CooordinatesType = {
    latitude?: number,
    longitude?: number,
}

export type LocationContextType = CooordinatesType & {
    setLatLng: (coor: CooordinatesType) => void,
}

const LocationContext = createContext<LocationContextType>({
    setLatLng: noop,
})

export default LocationContext
