import {
    css,
} from '@emotion/react'

const classes = {
    option: css`
        margin: 8px;
        display: block !important;
    `,
    detail: css`
        font-size: 12px;
        color: #000000;
        opacity: 60%;
    `,
}

export default classes
