import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import isNumber from 'lodash/isNumber'

import APP_URLS from '@src/constants/AppUrls'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'

import ConfirmDialog from '@src/shared-components/ConfirmDialog'
import useT from '@src/hooks/useT/useT'
import Carousel from '@src/shared-components/Carousel'
import FullScreen from '@src/shared-components/FullScreen'
import {
    MAX_NUMBER_OF_PHOTOS, MIN_NEEDED_NUMBERS_OF_PHOTOS,
} from '@src/constants/logic'

import TakePhotoOverviewPage from './components/TakePhotoOverviewPage'

function TakePhotoOverview(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        photos,
        removePhoto,
    } = usePhotoContext()
    const [
        fullScreen,
        setFullScreen,
    ] = useState<boolean>(false)
    const [
        showRemoveConfirmIndex,
        setShowRemoveConfirmIndex,
    ] = useState<number>()
    const [
        carouselIndex,
        setCarouselIndex,
    ] = useState(0)

    const navigate = useNavigate()

    const handleNextClick = useCallback(() => {
        navigate(APP_URLS.takePhoto)
    }, [navigate])

    const handleContinueClick = useCallback(() => {
        navigate(APP_URLS.locationConfirm)
    }, [navigate])

    const onRemoveHandle = useCallback((i: number) => {
        setShowRemoveConfirmIndex(i)
    }, [])

    const onConfirmRemove = useCallback((remove: boolean) => {
        if (remove && isNumber(showRemoveConfirmIndex)) {
            removePhoto(showRemoveConfirmIndex)
        }
        setShowRemoveConfirmIndex(undefined)
    }, [
        removePhoto,
        showRemoveConfirmIndex,
    ])

    const allowedTakePhoto = useMemo(() => {
        return photos.length < MAX_NUMBER_OF_PHOTOS
    }, [photos.length])

    const allowedContinue = useMemo(() => {
        return photos.length >= MIN_NEEDED_NUMBERS_OF_PHOTOS
    }, [photos])

    if (!photos.length) {
        navigate(APP_URLS.takePhotoOverviewEmpty)
    }

    return fullScreen ? (
        <FullScreen
            photos={photos}
            closeFullScreen={() => {
                setFullScreen(false)
            }}
            index={carouselIndex}
            setIndex={setCarouselIndex}
        />
    ) : (
        <>
            <TakePhotoOverviewPage
                onTakePhotoClick={handleNextClick}
                allowedContinue={allowedContinue}
                onContinueClick={handleContinueClick}
                allowedTakePhoto={allowedTakePhoto}
            >
                <Carousel
                    photos={photos}
                    removePhoto={onRemoveHandle}
                    setFullScreen={setFullScreen}
                    index={carouselIndex}
                    setIndex={setCarouselIndex}
                />
            </TakePhotoOverviewPage>
            <ConfirmDialog
                dialogTitle={t('CONFIRM_DELETE') as string}
                open={isNumber(showRemoveConfirmIndex)}
                dialogContent={t('CONFIRM_DELETE_MESSAGE') as string}
                handleClose={onConfirmRemove}
                negativeLabel={t('CANCEL') as string}
                positiveLabel={t('DELETE') as string}
            />
        </>
    )
}

export default TakePhotoOverview
