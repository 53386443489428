import {
    useContext,
} from 'react'
import {
    FormContext, IFormContext,
} from '../useForm/useForm'

type Props = {
    name: string
}
type Return = {
    context: IFormContext
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
}
function useField({
    name,
}: Props): Return {
    const context = useContext(FormContext)

    return {
        context,
        value: context.values[name],
    }
}

export default useField
