import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        row-gap: 12px;
    `,
    progress: css`
        padding: 24px;
    `,
    gatewayInstalled: css`
        margin-bottom: -4px;
        margin-right: 16px;
    `,
}

export default classes
