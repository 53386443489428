import {
    css,
} from '@emotion/react'

const classes = {
    icon: css`
        align-self: center;
        width: 24px;
        flex: 0 0 24px;
    `,
    coordinatesCnt: css`
        display: flex;
        flex-direction: column;
        width: 100%;
    `,
    row: css`
        display: flex;
        flex-direction: row;
    `,
    header: css`
        padding: 0px 16px;
        text-align: left;
    `,
    subHeader: css`
        padding: 0px 16px;
        text-align: left;
    `,
    hr: css`
        border-top: 1px solid black;
        opacity: 0.12;
        margin-top: 8px;
        margin-bottom: 8px;
    `,
    text: css`
        flex: 1;
    `,
}

export default classes
