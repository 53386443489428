import React, {
    ReactNode,
} from 'react'
import MenuSvg from '@src/assets/menu.svg'
import LogoSvg from '@src/assets/headerlogo.svg'
import classes from './Header.style'

type Props = {
    title: ReactNode
    className?: string
    onMenuClick?: () => void
    onLogoClick?: () => void
}

function Header({
    title, className, onMenuClick, onLogoClick,
}: Props): JSX.Element {
    return (
        <div
            className={className}
            css={classes.root}
        >
            <div
                css={classes.logo}
                onClick={onLogoClick}
                onKeyDown={onLogoClick}
            >
                <img
                    alt="Logo"
                    src={LogoSvg}
                />
                <div css={classes.brandTitle}>SkyMind</div>
            </div>

            <div css={classes.title}>{title}</div>
            <div
                css={classes.menu}
                onClick={onMenuClick}
                onKeyDown={onMenuClick}
            >
                <img
                    alt="Menu"
                    src={MenuSvg}
                    css={classes.menuSvg}
                />
            </div>
        </div>
    )
}

export default Header
