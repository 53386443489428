const URL = 'gateway-detected'
const parseGatewayQRCode = (scanedString: string): string | null => {
    const {
        origin,
    } = window.location
    const preparedOrigin = `${origin}`.replace(/\//g, '\\/')
    const temple = `${preparedOrigin}\\/${URL}\\/(.*)`
    const regExp = new RegExp(temple)
    const resultTest = scanedString.match(regExp)

    return resultTest && resultTest.length > 1 ? resultTest[1] : null
}

export default parseGatewayQRCode
