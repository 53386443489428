import {
    css,
} from '@emotion/react'
import THEME from '@src/constants/Theme'

const classes = {
    form: css`
        height: 100%;
        min-height: 100%;
    `,
    formContent: css`
        display: grid;
        grid-row-gap: 12px;
        padding: 16px 32px;
    `,
    code: css`
        color: ${THEME.skycell_primary_color};
        padding: 22px;
        text-transform: uppercase;
    `,
    checkIcon: css`
        margin-bottom: -6px;
        padding-right: 16px;
        width: 24px;
        height: 30px;
    `,
}

export default classes
