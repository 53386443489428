import React, {
    ReactNode,
    useState,
} from 'react'
import {
    Trans,
} from 'react-i18next'

import Button from '@src/shared-components/Button'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'

import classes from './TakePhotoOverviewPage.style'

type Props = {
    onBackClick?: () => void,
    onTakePhotoClick: ()=> void,
    onContinueClick?: ()=>void,
    allowedContinue?: boolean,
    allowedTakePhoto: boolean,
    children: ReactNode,
    takePhotoPrimary?: boolean,
}

function TakePhotoOverviewPage({
    onBackClick,
    onTakePhotoClick,
    onContinueClick,
    allowedContinue,
    allowedTakePhoto,
    children,
    takePhotoPrimary,
}:Props): JSX.Element {
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)
    const {
        t,
    } = useT()

    return (
        <Layout
            header={(
                <Header
                    title={t('INSTALL_GATEWAY')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <Button
                        variant={takePhotoPrimary ? 'primary' : 'primary-outlined'}
                        onClick={onTakePhotoClick}
                        disabled={!allowedTakePhoto}
                    >
                        {t('TAKE_PHOTO')}
                    </Button>

                    { onContinueClick && (
                        <Button
                            variant="primary"
                            onClick={onContinueClick}
                            disabled={!allowedContinue}
                        >
                            {t('CONTINUE')}
                        </Button>
                    )}
                    { onBackClick && (
                        <Button
                            variant="primary-outlined"
                            onClick={onBackClick}
                        >
                            {t('BACK')}
                        </Button>
                    )}
                </>
            )}
        >
            <div css={classes.root}>
                <MainPopup
                    isOpen={isPopupOpened}
                    onClose={() => {
                        return showMainPopup(false)
                    }}
                />
                <Typography
                    variant="headline5"
                    css={classes.header}
                >
                    {t('TAKE_A_PHOTOS_HEADER')}
                </Typography>
                <Typography
                    variant="subtitle1"
                    css={classes.subHeader}
                >
                    <Trans
                        i18nKey="TAKE_PHOTO_OVERVIEW_SUB_HEADER"
                        components={{
                            strong: <strong />,
                        }}
                    />
                </Typography>
                {children}
            </div>
        </Layout>
    )
}

export default TakePhotoOverviewPage
