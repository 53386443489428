import {
    useCallback,
    useMemo,
} from 'react'

import useSetup from '@src/hooks/useSetup/useSetup'
import useSaveAttachments from '@src/api/hooks/useSaveAttachments'
import useConfirmInstallation from '@src/api/hooks/useConfirmInstallation'
import {
    PhotoType,
} from '@src/context/PhotosContext/PhotosContext'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'
import mergePhotos from '@src/context/PhotosContext/utils'
import {
    useLocationContext,
} from '@src/context/LocationContext'

const convertPhotoTypeForRequest = (results: PhotoType[]) => {
    return results.map((result: PhotoType) => {
        return {
            id: result.id as number,
        }
    })
}
const useConfirmGatewayInstallation = (onSuccess:()=> void) => {
    const [state] = useSetup()
    const {
        latitude,
        longitude,
    } = useLocationContext()
    const {
        savePhotoIds,
        photos: contextPhotos,
        cleanUp,
    } = usePhotoContext()
    const afterSuccess = useCallback(() => {
        cleanUp()
        onSuccess()
    }, [
        onSuccess,
        cleanUp,
    ])
    const {
        mutate: confirmInstallationMutate,
        isPending: confirmPending,
        error: confirmError,
        reset: resetConfirmError,
    } = useConfirmInstallation(afterSuccess)
    const afterSaveAttachment = useCallback((results: PhotoType[]) => {
        const mergedResults = mergePhotos(results, contextPhotos)

        savePhotoIds(results)
        confirmInstallationMutate({
            id: state.gatewayInfo?.id || 0,
            body: {
                area: {
                    id: state.selectedArea?.id || 0,
                },
                gatewayPictures: convertPhotoTypeForRequest(mergedResults),
                location: {
                    id: state.selectedLocation?.id || 0,
                },
                latitude: latitude || 0,
                longitude: longitude || 0,
            },
        })
    }, [
        confirmInstallationMutate,
        state.gatewayInfo,
        state.selectedArea,
        state.selectedLocation,
        latitude,
        longitude,
        savePhotoIds,
        contextPhotos,
    ])

    const {
        mutate: saveAttachements,
        error: saveAttsError,
        isPending: saveAttsPending,
        reset: resetSaveAtts,
    } = useSaveAttachments(`${state.gatewayInfo?.id}`, afterSaveAttachment)

    const saveAndConfirm = useCallback(() => {
        const notSavedPhotos = contextPhotos.filter((photo) => {
            return photo.id === undefined
        })

        return saveAttachements(notSavedPhotos)
    }, [
        saveAttachements,
        contextPhotos,
    ])

    const error = useMemo(() => {
        return saveAttsError || confirmError
    }, [
        confirmError,
        saveAttsError,
    ])

    const isLoading = useMemo(() => {
        return saveAttsPending || confirmPending
    }, [
        saveAttsPending,
        confirmPending,
    ])

    const resetErrors = useCallback(() => {
        resetConfirmError()
        resetSaveAtts()
    }, [
        resetConfirmError,
        resetSaveAtts,
    ])

    return {
        error,
        isLoading,
        saveAndConfirm,
        resetErrors,
    }
}

export default useConfirmGatewayInstallation
