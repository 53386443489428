import {
    css,
} from '@emotion/react'

const classes = {
    icon: css`
      position: absolute;
      bottom: 15px;
      right: 15px;
    `,
}

export default classes
