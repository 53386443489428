import React, {
    ReactNode,
} from 'react'
import CheckSvg from '@src/assets/check.svg'
import classes from './Progress.style'

export enum EProgressState {
  default,
  inProgress,
  completed,
}
export interface IProgressOption {
  label: ReactNode;
}
type Props = {
  options: IProgressOption[];
  currentStep: number;
};

const getState = (currentStep: number, index: number) => {
    if (currentStep === index) {
        return EProgressState.inProgress
    }
    return currentStep < index
        ? EProgressState.default
        : EProgressState.completed
}

function Progress({
    currentStep, options,
}: Props): JSX.Element {
    return (
        <div css={classes.root}>
            {options.map((option, index) => {
                const state = getState(currentStep, index)
                const key = `${option.label}-${index}`

                return (
                    <div
                        key={key}
                        css={classes.item(100 / options.length)}
                    >
                        <div css={classes.itemContainer}>
                            <div css={classes.line(index !== 0)} />
                            <div css={classes.circle(state)}>
                                {state === EProgressState.completed ? (
                                    <img
                                        alt="Complete"
                                        src={CheckSvg}
                                        css={classes.img}
                                    />
                                ) : (
                                    index + 1
                                )}
                            </div>
                            <div css={classes.line(index !== options.length - 1)} />
                        </div>
                        <div css={classes.label}>{option.label}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default Progress
