function dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const [
        url,
        data,
    ] = splitDataURI
    const byteString = url.indexOf('base64') >= 0 ? atob(data) : decodeURI(data)
    const [
        , mimeData,
    ] = url.split(':')
    const [mimeString] = mimeData.split(';')

    const ia = new Uint8Array(byteString.length)

    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], {
        type: mimeString,
    })
}

export default dataURIToBlob
